import { Select, Spin } from 'antd'
import React, { useEffect, useState } from 'react'
import { useDispatch } from 'react-redux'
import { Link } from 'react-router-dom'
import { useSelector } from 'store'
import { goalsActions } from 'store/reducers/goalsReducer'
import styles from './Goals.module.css'
import GoalCard from 'components/GoalCard/GoalCard'
import { Goal } from '../../typings/dataStructures'
const { Option } = Select

const Goals = () => {
  const dispatch = useDispatch()
  const [sortTarget, setSortTarget] = useState('target_amount')
  const goals = useSelector((state) => state.goals.goals)
  const isFetchGoals = useSelector((state) => state.goals.isFetchGoals)
  const loading = useSelector((state) => state.goals.fetching)

  const compareFunction = (a: Goal, b: Goal) => {
    switch (sortTarget) {
      case 'name':
        return a.name.localeCompare(b.name)
      case 'progress':
        return a.current_amount / a.target_amount > b.current_amount / b.target_amount ? -1 : 1
      default:
        // @ts-ignore
        return a[sortTarget] > b[sortTarget] ? -1 : 1
    }
  }

  useEffect(() => {
    if (goals === undefined || !isFetchGoals) dispatch(goalsActions.getGoals())
  }, [goals])

  if (loading) return <Spin />
  else {
    return (
      <div className={styles.container}>
        <div className={styles.sortContainer}>
          <p className={styles.sortLabel}>sort by:</p>
          <Select
            defaultValue="target_amount"
            onChange={setSortTarget}
            dropdownMatchSelectWidth={false}
            bordered={false}>
            <Option value="target_amount">Target amount</Option>
            <Option value="current_amount">Current amount</Option>
            <Option value="progress">Progress</Option>
            <Option value="name">Name</Option>
          </Select>
        </div>
        {goals ? (
          <div className={styles.goalsContainer}>
            {[...goals]
              // @ts-ignore
              .sort(compareFunction)
              .map((g) => (
                <GoalCard key={g.id} goal={g} />
              ))}
            <Link className={styles.addGoalCard} to={'/configurator'} />
          </div>
        ) : (
          <Link to={'/configurator'}>
            <button className={styles.newGoalBtn}>New goal</button>
          </Link>
        )}
      </div>
    )
  }
}

export default Goals
