import React, { useState } from 'react'
import { Button, Empty, Form, Input, message, Row } from 'antd'
import { LinkedinFilled, ReloadOutlined } from '@ant-design/icons'
import { Link, useHistory } from 'react-router-dom'
import CategoriesSelect from '../../ui/customFormInputs/CategoriesSelect'
import {
  IndustrySelect,
  SectorContext,
  SectorContextValueType,
  SectorSelect,
  SectorTypeSelect,
} from '../../ui/customFormInputs/FundSelect'
import * as api from 'api'
import { InstrumentProps } from 'api'

const AddProduct = () => {
  const [form] = Form.useForm<InstrumentProps>()
  const history = useHistory()
  const onOk = async () => {
    const values = await form.validateFields().catch(() => null)
    if (!values) return
    const resp = await api.createInstrument({
      fund: fundId,
      name: values.name,
      category: values.category,

      industry: values.industry,
      sector: values.sector,
      sector_type: values.sector_type,
    })
    if (resp.data) {
      message.success('Product successfully added').then(() => history.push('/backend'))
    } else {
      message.error('Error occurred while adding product')
    }
  }
  if (new URL(location.href).searchParams.get('fundId') === null)
    return (
      <div
        style={{
          display: 'flex',
          alignItems: 'center',
          justifyContent: 'center',
          height: 400,
          width: '100%',
          flexDirection: 'column',
        }}>
        <Empty description={'Error occurred while loading fund profile'} />
        <Link to={'/backend'}>Back</Link>
      </div>
    )
  const fundId = parseInt(new URL(location.href).searchParams.get('fundId')!)
  const [ctx, setCtx] = useState<SectorContextValueType>({ secType: undefined, sector: undefined, industry: undefined })
  return (
    <div>
      <Link to={'/backend'}>Back</Link>
      <h1 style={{ marginTop: 10 }}>Add new product</h1>
      <Form form={form}>
        <Form.Item name={'name'} label={'Name'}>
          <Input placeholder={'Product name'} />
        </Form.Item>
        <Form.Item name={'category'} label={'Category'}>
          <CategoriesSelect />
        </Form.Item>
        <SectorContext.Provider value={{ ctx, setCtx: setCtx }}>
          <Form.Item name={'sector_type'} label={'Sector type'}>
            <SectorTypeSelect
              onChange={() => {
                form.setFieldsValue({ sector: undefined, industry: undefined })
              }}
            />
          </Form.Item>
          <Form.Item name={'sector'} label={'Sector'}>
            <SectorSelect
              onChange={() => {
                form.setFieldsValue({ industry: undefined })
              }}
            />
          </Form.Item>
          <Form.Item name={'industry'} label={'Industry'}>
            <IndustrySelect />
          </Form.Item>
        </SectorContext.Provider>

        <Row justify={'end'}>
          <Button type={'primary'} onClick={onOk}>
            Add product
          </Button>
        </Row>
      </Form>
    </div>
  )
}

export default AddProduct
