import { createSlice, PayloadAction } from '@reduxjs/toolkit'

type User = {
  username: string
  id: number
  userType: USER_TYPE
  first_name?: string
  last_name?: string
}
export enum USER_TYPE {
  CUSTOMER = 'CUSTOMER',
  FUND = 'FUND',
}
type ProfileState = {
  token?: string
  user?: User
}
const initialState: ProfileState = {
  // token: 'the token',
  // user: {
  //   username: 'User',
  //   pk: 0,
  // },
  // userType: USER_TYPE.CUSTOMER,
}
const slice = createSlice({
  name: 'profile',
  initialState,
  reducers: {
    logout: (state) => {
      state.token = undefined
      state.user = undefined
    },
    setToken: (state, action) => {
      state.token = action.payload
    },
    setUser: (state, action: PayloadAction<User>) => {
      state.user = action.payload
    },
    setUserType: (state, action: PayloadAction<USER_TYPE>) => {
      if (state.user) {
        state.user.userType = action.payload
      }
    },
    setTokenAndUser: (state, action: PayloadAction<{ user: User; token: string }>) => {
      state.user = action.payload.user
      state.token = action.payload.token
    },
  },
})

export const { actions: profileActions, reducer: profileReducer } = slice
