import React from 'react'
import { Redirect, Route, Switch } from 'react-router-dom'
import Home from 'pages/Home/Home'
import Login from 'pages/Login/Login'
import { useSelector } from 'store'
import Register from 'pages/Login/Register'
import Goals from 'pages/Goals/Goals'
import { USER_TYPE } from './store/reducers/profileReducer'
import Backend from './pages/Backend/Backend'
import AddProduct from './pages/AddProduct/AddProduct'
import AddFund from './pages/AddFund/AddFund'
import EditProduct from './pages/EditProduct/EditProduct'
import ConfiguratorContainer from './pages/Configurator/ConfiguratorContainer'

const authRoutes = [
  {
    path: '/login',
    Component: Login,
  },
  {
    path: '/manager_login',
    Component: Login,
  },
  {
    path: '/register',
    Component: Register,
  },
  {
    path: '/configurator',
    Component: Login,
  },
  {
    path: '/goals',
    Component: Login,
  },
]

const customerRoutes = [
  {
    path: '/configurator',
    Component: ConfiguratorContainer,
  },
  {
    path: '/goals',
    Component: Goals,
  },
]

const fundRoutes = [
  {
    path: '/backend',
    Component: Backend,
  },
  {
    path: '/add_product',
    Component: AddProduct,
  },
  {
    path: '/edit_product',
    Component: EditProduct,
  },
  {
    path: '/add_fund',
    Component: AddFund,
  },
]

const Router = () => {
  const profile = useSelector((store) => store.profile)
  let currentRoutes = authRoutes

  const isCustomer = profile.token && profile.user?.userType === USER_TYPE.CUSTOMER
  const isFund = profile.token && profile.user?.userType === USER_TYPE.FUND

  if (isCustomer) currentRoutes = customerRoutes
  if (isFund) currentRoutes = fundRoutes

  return (
    <Switch>
      <Route exact path={'/'} component={Home} />
      {currentRoutes.map(({ path, Component }) => (
        <Route exact key={path} path={path} component={Component} />
      ))}
      <Redirect to={'/'} />
    </Switch>
  )
}

export default Router
