import React from 'react'
import Highcharts from 'highcharts/highcharts'

// require('highcharts/modules/boost')(Highcharts);
// require('highcharts/highcharts-more')(Highcharts);
import HighchartsReact from 'highcharts-react-official'
import { useSelector } from '../../../store'

type Props = {
  data?: [string, number][]
}
export const pct = (v: number, decimals = 2) => {
  const d = Math.pow(10, decimals)
  return Math.round(v * 100 * d) / d
}

const BarChart = React.forwardRef(({ data }: Props, ref: any) => {
  const themeColor = useSelector((store) => store.app.themeColor)
  const options: Highcharts.Options = {
    chart: { type: 'bar' },
    title: { text: '' },
    credits: { enabled: false },
    xAxis: {
      categories: data?.map((e) => e[0]),
      // title: { text: 'strategies' },
    },
    yAxis: {
      min: 0,
      title: { text: '' },
      labels: {
        enabled: false,
        overflow: 'justify',
      },
    },
    tooltip: {
      // formatter: function() {
      //
      // },
      valueSuffix: ' %',
    },
    plotOptions: {
      bar: {
        dataLabels: {
          enabled: true,
          formatter: function () {
            return this.point.y !== undefined ? pct(this.point.y, 1) + '%' : this.point.y
          },
        },
      },
    },
    legend: {
      enabled: false,
      layout: 'vertical',
      align: 'right',
      verticalAlign: 'top',
      x: -40,
      y: 80,
      floating: true,
      borderWidth: 1,
      // backgroundColor: Highcharts.defaultOptions.legend.backgroundColor || '#FFFFFF',
      shadow: true,
    },

    series: [
      {
        type: 'bar',
        name: 'Strategies',
        data: data?.map((e) => e[1]),
        color: themeColor,
      },
    ],
  }

  return (
    <HighchartsReact
      ref={ref}
      highcharts={Highcharts}
      options={options}
      // style={{ width: '100%' }}
    />
  )
})

BarChart.displayName = 'BarChart'

export default BarChart
