import React, { CSSProperties, useEffect, useState } from 'react'
import { InputNumber, Slider } from 'antd'
import styles from './SliderInput.module.css'
type SliderValueType = number | [number, number]
type Props<T extends SliderValueType = SliderValueType> = {
  min: number
  max: number
  name?: string
  value?: T
  onChange?: (value: T) => void
  range?: boolean
  inputStyle?: CSSProperties
  marks?: boolean
  step?: number
}

export const SliderInput: React.FC<Props> = ({
  name,
  value,
  onChange,
  min,
  max,
  range = false,
  inputStyle,
  marks,
  step,
}) => {
  const [v, setValue] = useState<SliderValueType | undefined>(value)
  const dispatchChange = (newVal: any) => {
    onChange?.(newVal)
  }
  useEffect(() => {
    setValue(value)
  }, [value])
  return (
    <div className={styles.main}>
      {name !== undefined ? <p className={styles.name}>{name}</p> : null}
      <div className={styles.wrapper}>
        {range === false ? (
          <div className={styles.number}>
            <InputNumber
              size={'small'}
              style={{ width: 80, marginRight: 8 }}
              // style={inputStyle ?? { marginRight: 8 }}
              value={v as number}
              min={min}
              max={max}
              step={step ?? 0.01}
              onChange={(e) => {
                setValue(e)
                dispatchChange(e)
              }}
            />
          </div>
        ) : null}

        {/*//@ts-ignore*/}
        <Slider
          className={styles.slider}
          value={v}
          range={range}
          marks={
            marks
              ? {
                  [min.toString()]: min,
                  [max.toString()]: max,
                }
              : undefined
          }
          onAfterChange={dispatchChange}
          onChange={setValue}
          min={min}
          max={max}
          step={step ?? 0.01}
        />
      </div>
    </div>
  )
}

export default SliderInput
