import { createSlice, PayloadAction } from '@reduxjs/toolkit'
import { Goal } from '../../typings/dataStructures'
import * as api from '../../api'
import { store } from '../index'
import { CreateGoalReq } from '../../api'

type GoalsState = {
  goals?: null | Goal[]
  fetching: boolean
  isFetchGoals: boolean
}

const initialState: GoalsState = {
  isFetchGoals: false,
  fetching: false,
}

const slice = createSlice({
  name: 'goals',
  initialState,
  reducers: {
    addGoal: (state, action: PayloadAction<Goal>) => {
      const old = state.goals ?? []
      const new_Goals = [...old.filter((g) => g.id !== action.payload.id), action.payload]
      state.goals = new_Goals
    },
    // updateGoal: (state, action: PayloadAction<Goal>) => {
    //   if (state.goals === null) {
    //     state.goals = [action.payload]
    //   } else {
    //     state.goals = state.goals?.map((g) => (g.id === action.payload.id ? action.payload : g))
    //   }
    // },
    setGoals: (state, action: PayloadAction<null | Goal[]>) => {
      state.goals = action.payload
    },
    deleteGoal: (state, action: PayloadAction<number>) => {
      if (state.goals === null) return
      state.goals = state.goals?.filter((g) => g.id !== action.payload)
    },
    setFetching: (state, action: PayloadAction<boolean>) => {
      state.fetching = action.payload
    },
    setIsFetchGoals: (state, action: PayloadAction<boolean>) => {
      state.isFetchGoals = action.payload
    },
    getGoals: () => {
      //gatewaySaga.js
    },
  },
})

// export const createGoal = async (
//   goal: ,
// ) => {
//   const dispatch = store.dispatch
//   const resp = await api.createGoal(goal)
//   if (resp.data) {
//     //@ts-ignore
//     dispatch(goalsActions.setGoal(resp.data))
//   }
//   return resp
// }

// export const updateGoal = async (id: number, goal: CreateGoalReq) => {
//   const dispatch = store.dispatch
//   delete goal.id
//   const resp = await api.updateGoal(id, goal)
//   if (resp.data) {
//     //@ts-ignore
//     dispatch(goalsActions.updGoal(resp.data))
//   }
//   return resp
// }

// export const getGoals = async () => {
//   const dispatch = store.dispatch;
//   dispatch(goalsActions.setFetching(true));
//   const resp = await api.getGoals();
//   if (resp.data) {
//     dispatch(goalsActions.setGoals(resp.data));
//   } else {
//     dispatch(goalsActions.setGoals(null));
//   }
//   dispatch(goalsActions.setFetching(false));
//   return resp;
// };

export const deleteGoal = async (id: number) => {
  const dispatch = store.dispatch
  const resp = await api.deleteGoal(id)
  if (resp.status == 200) {
    dispatch(goalsActions.deleteGoal(id))
  }
  return resp
}

export const { actions: goalsActions, reducer: goalsReducer } = slice
