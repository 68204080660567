import React, { useEffect, useState } from 'react'
import { FormInstance, message, Row, Spin } from 'antd'
import { useHistory } from 'react-router-dom'
import { useDispatch } from 'react-redux'
import { useSelector } from 'store'
import { Goal } from 'typings/dataStructures'
import UITexts from 'export/UITexts'
import { goalsActions } from 'store/reducers/goalsReducer'
import ConfiguratorForm, { ConfiguratorFormFields } from './ConfiguratorForm'
import styles from './Configurator.module.css'
import * as api from 'api'
import { CreateGoalReq } from 'api'

const Configurator = () => {
  const history = useHistory()
  const dispatch = useDispatch()
  const goalIdUrl = new URL(window.location.href).searchParams.get('id')
  const goals = useSelector((state) => state.goals.goals)
  const loading = useSelector((state) => state.goals.fetching)
  const [isLoading, setIsLoading] = useState(false)
  let goalId: number | null = null
  if (goalIdUrl !== null) {
    goalId = parseInt(goalIdUrl)
  }
  const isNew = goalId === null

  const getDetailedGoal = async (id: number) => {
    const resp = await api.getGoal(id)
    if (resp.data) {
      dispatch(goalsActions.addGoal(resp.data))
    }
  }

  useEffect(() => {
    if (goalId !== null && goals === undefined) {
      dispatch(goalsActions.getGoals())
    }
  }, [goalId])

  let initialValues: ConfiguratorFormFields = {
    name: 'Goal name',
    target_amount: 200_000,
    initial_amount: 10_000,
    payment_plan: 'Monthly',
    risk_level: 1,
    payment_size: 1000,
    current_amount: 0,
    daily_drawdown: 0.01,
    sharpe: 0.5,
    categories: [],
    payments: [],
  }
  let detailedGoalData = undefined
  if (goalId !== null && goals === undefined)
    return (
      <Row justify={'center'}>
        <Spin />
      </Row>
    )
  let goal: Goal | undefined = undefined
  let wentToRequestDetails = false
  if (goalId !== null) {
    goal = goals?.find((g) => g.id === goalId)
    console.log('goa;!!', goal)
    if (goal) {
      if (goal.chart === undefined) {
        wentToRequestDetails = true
        getDetailedGoal(goalId)
        goal = undefined
      } else {
        const chartData = [] as [number, number][]
        const upData = [] as [number, number][]
        const downData = [] as [number, number][]
        const ranges = [] as [number, number, number][]
        const times = goal.times!
        const chart = goal.chart!
        const up_bound = goal.up_bound!
        const down_bound = goal.down_bound!
        times.forEach((ts, i) => {
          chartData.push([ts * 1000, chart[i]])
          upData.push([ts * 1000, up_bound[i]])
          downData.push([ts * 1000, down_bound[i]])
          ranges.push([ts * 1000, down_bound[i], up_bound[i]])
        })
        detailedGoalData = {
          chart: [chartData, ranges],
          payments: [],
        }
      }
    }
  }

  if (goalId !== null && goal === undefined) {
    if (wentToRequestDetails) {
      return (
        <Row justify={'center'}>
          <Spin />
        </Row>
      )
    } else return <h3 style={{ color: 'red' }}>Goal not found</h3>
  }
  if (goalId !== null && goal !== undefined) {
    const categories = Array.from(
      new Set(goal.instruments.filter((i) => i.instrument.category !== null).map((i) => i.instrument.category!.id)),
    )
    const instruments = goal.instruments.map((i) => ({
      name: i.instrument.name,
      instrument: i.instrument.name,
      weight: i.weight,
      id: i.instrument.id,
      is_fixed: i.is_fixed,
    }))
    initialValues = { ...goal, categories, instruments, fixed_instruments: instruments.filter((i) => i.is_fixed) }
  }

  const onSubmit = async (form: FormInstance<ConfiguratorFormFields>) => {
    setIsLoading(true)
    const values = await form.validateFields().catch(() => null)
    if (!values) {
      setIsLoading(false)
      return
    }
    const goalData: CreateGoalReq = {
      name: values.name,
      payment_plan: values.payment_plan,
      payment_size: values.payment_size,
      initial_amount: values.initial_amount,
      current_amount: values.current_amount,
      target_amount: values.target_amount,
      daily_drawdown: values.daily_drawdown,
      sharpe: values.sharpe,
      risk_level: values.risk_level,
      instruments: values.instruments?.map((e) => ({ instrument: e.id, weight: e.weight, is_fixed: e.is_fixed })) ?? [],
      payments: values.payments.map((p) => ({ date: p.date, amount: p.amount })),

      bound_k: values.bound_k!,
      annual_profit: values.annual_profit!,
      monthly_profit: values.monthly_profit!,
      daily_profit: values.daily_profit!,
      weekly_profit: values.weekly_profit!,
      estimate_date: values.estimate_date!,
    }
    if (isNew) {
      const resp = await api.createGoal(goalData)
      // return
      if (resp.data) {
        dispatch(goalsActions.addGoal(resp.data))
        message.success(UITexts.goal.CREATE_SUCCESS)
        history.push('/goals')
      } else {
        message.error(UITexts.goal.CREATE_ERROR)
        setIsLoading(false)
      }
    } else {
      const id = goalId
      if (id === null) {
        setIsLoading(false)
        return
      }
      const resp = await api.updateGoal(id, goalData)
      if (resp.data) {
        message.success(UITexts.goal.UPDATE_SUCCESS)
        dispatch(goalsActions.addGoal(resp.data))
        history.push('/goals')
      } else {
        message.error(UITexts.goal.UPDATE_ERROR)
        setIsLoading(false)
      }
    }
  }

  return (
    <div className={styles.main} key={goalId}>
      <div className={styles.titleContainer}>
        <h1 className={styles.title}>Goal configurator</h1>
        {/* Добавил иконку QR-code, в будущем можно добавить импорт goal отсюда для толстых пальцев */}
        {/* {!isNew && <img src={qrCode} className={styles.qrCodeIcon} alt="" />} */}
      </div>
      {!isNew && loading && <Spin />}
      <ConfiguratorForm
        detailedGoalData={detailedGoalData}
        onSubmit={onSubmit}
        submitBtnText={isNew ? 'Create' : 'Save'}
        initialValues={initialValues}
        isLoading={isLoading}
      />
    </div>
  )
}

export default Configurator
