import React, { useEffect, useState } from 'react'
import { Button, Card, Empty, Popover, Spin, Upload } from 'antd'
import * as api from 'api'
import { Link, useHistory } from 'react-router-dom'
import { DeleteOutlined, EditOutlined, LinkOutlined, ReloadOutlined, UploadOutlined } from '@ant-design/icons'
import styles from './Products.module.css'
import ProductChart from './ProductChart'
import { pct } from '../../../ui/customFormInputs/PortfolioChart/BarChart'
import { deleteInstrument, InstrumentType } from 'api'

type Product = {
  id: number
  name: string
  chart: [number, number][]
  annual_profit: number
}
const buildPath = (inst: InstrumentType) => {
  const p = [inst.sector_type?.name, inst.sector?.name, inst.industry?.name].filter(Boolean)
  if (p.length) return p.join('/')
  return ''
}
const ProductCard = ({
  p,
  updateInstrument,
  onDelete,
}: {
  p: InstrumentType
  updateInstrument: (arg: InstrumentType) => void
  onDelete: () => void
}) => {
  const [fileList, setFileList] = useState([
    {
      uid: '-1',
      name: 'productdata.csv',
      status: 'done',
      url: 'https://zos.alipayobjects.com/rmsportal/jkjgkEfvpUPVyRjUImniVslZfWPnJuuZ.png',
    },
  ])
  const [e, se] = useState(false)
  const [l, sl] = useState(false)
  return (
    <Card
      title={p.name + ' ' + buildPath(p)}
      size={'small'}
      extra={
        <Popover
          trigger={'click'}
          zIndex={1}
          content={
            <div>
              <div>
                <Link to={`edit_product?id=${p.id}`}>Edit product</Link>
              </div>
              <Button type={'primary'}>Promote</Button>
              <div>
                <a href={`https://wealth.splinex.com/api/load_csv/${p.id}`}>Download CSV</a>
              </div>
              <Upload
                // progress={}
                showUploadList={false}
                // type={}
                listType={undefined}
                //@ts-ignore
                fileList={fileList}
                accept={'/upload_product_csv'}
                beforeUpload={async (f) => {
                  sl(true)
                  const formData = new FormData()
                  formData.set('file', f)
                  const resp = await api.instrumentUploadCSV(p.id, formData)

                  // props.updateInstrument()
                  sl(false)
                  if (resp.data) {
                    updateInstrument(resp.data)
                  }
                  return false
                }}>
                <Button loading={l} icon={<UploadOutlined />}>
                  Upload CSV
                </Button>
              </Upload>
              <Button
                icon={<DeleteOutlined />}
                danger
                onClick={async () => {
                  const resp = await api.deleteInstrument(p.id)
                  if (resp.data) {
                    onDelete()
                  }
                }}
              />
            </div>
          }>
          <EditOutlined />
        </Popover>
      }>
      {/*<p>product description</p>*/}
      <p>
        Annual profit: <b>{pct(p.annual_profit)}%</b>
      </p>
      {/*<div>{}</div>*/}
      {/*<p>*/}
      {/*  Sector type: <b>{p.sector_type?.name ?? '-'}</b>*/}
      {/*</p>*/}
      {/*<p>*/}
      {/*  Sector: <b>{p.sector_type?.name ?? '-'}</b>*/}
      {/*</p>*/}
      {p.chart.length === 0 && (
        <p
          style={{
            textAlign: 'center',
            textTransform: 'uppercase',
            fontWeight: 'bold',
            fontSize: '16px',
            color: 'rgba(0,0,0,0.46)',
            marginTop: 80,
          }}>
          no data
        </p>
      )}
      {p.chart.length !== 0 && <ProductChart data={p.chart} />}
    </Card>
  )
}

const Products = (props: { fundId: number }) => {
  const [products, setProducts] = useState<InstrumentType[] | null | undefined>()
  const history = useHistory()

  useEffect(() => {
    getProducts()
  }, [])
  const getProducts = async () => {
    const resp = await api.getProducts(props.fundId)
    if (resp.data) {
      setProducts(resp.data)
    } else {
      setProducts(null)
    }
  }
  const goToAddProduct = () => history.push(`/add_product?fundId=${props.fundId}`)
  if (products === undefined)
    return (
      <div style={{ display: 'flex', alignItems: 'center', justifyContent: 'center', height: 400, width: '100%' }}>
        <Spin />
      </div>
    )
  if (products === null)
    return (
      <div
        style={{
          display: 'flex',
          alignItems: 'center',
          justifyContent: 'center',
          height: 400,
          width: '100%',
          flexDirection: 'column',
        }}>
        <Empty description={'Error occurred while loading products'} />
        <Button icon={<ReloadOutlined />} onClick={getProducts} style={{ marginTop: 20 }} />
      </div>
    )
  if (products.length === 0)
    return (
      <div>
        <Empty description={'You dont have any products yet'} />
        <Button
          style={{ margin: '0 auto', display: 'block', marginTop: 20 }}
          type={'primary'}
          icon={<LinkOutlined />}
          onClick={goToAddProduct}>
          Add new product
        </Button>
      </div>
    )
  return (
    <div className={styles.productList}>
      {products
        .sort((a, b) => b.id - a.id)
        .map((p) => (
          <ProductCard
            p={p}
            key={p.id}
            updateInstrument={(ppp) => {
              setProducts([...(products ?? []).filter((pp) => pp.id !== p.id), ppp])
            }}
            onDelete={() => {
              setProducts([...(products ?? []).filter((pp) => pp.id !== p.id)])
            }}
          />
        ))}
      <div style={{ display: 'flex', alignItems: 'center', justifyContent: 'center' }}>
        <Button
          // style={{ margin: '0 auto', display: 'block', marginTop: 20 }}
          type={'primary'}
          icon={<LinkOutlined />}
          onClick={goToAddProduct}>
          Add new product
        </Button>
      </div>
    </div>
  )
}

export default Products
