import React from 'react'
import { Button, Empty, Form, Input, message, Row } from 'antd'
import { LinkedinFilled, ReloadOutlined } from '@ant-design/icons'
import { Link, useHistory } from 'react-router-dom'

import * as api from 'api'

const AddFund = () => {
  const [form] = Form.useForm()
  const history = useHistory()
  const onOk = async () => {
    const values = await form.validateFields().catch(() => null)
    if (!values) return
    const resp = await api.createFund(values)
    if (resp.data) {
      message.success('Fund successfully added').then(() => history.push('/backend'))
    } else {
      message.error('Error occurred while adding fund')
    }
  }
  return (
    <div>
      <Link to={'/backend'}>Back</Link>
      <h1 style={{ marginTop: 10 }}>Add new fund</h1>
      <Form form={form}>
        <Form.Item name={'name'} label={'Name'}>
          <Input />
        </Form.Item>
        <Row justify={'end'}>
          <Button type={'primary'} onClick={onOk}>
            Add fund
          </Button>
        </Row>
      </Form>
    </div>
  )
}

export default AddFund
