import React from 'react'
import BarChart from './BarChart'

type Props = { value?: { name: string; id: number; weight: number }[]; onChange?: () => void }

const PortfolioChart: React.FC<Props> = ({ value, onChange }) => {
  if (value === undefined) return null
  else return <BarChart data={value.map((e) => [e.name, e.weight] as [string, number]).sort((a, b) => b[1] - a[1])} />
}

export default PortfolioChart
