/* eslint-disable react/display-name */
import React from 'react'

export default {
  login: {
    LOGIN_SUCCESS: 'You were successfully logged in',
  },
  goalPreset: {
    CREATE_SUCCESS: 'Goal preset successfully added',
    CREATE_ERROR: 'Error occurred while creating goal preset',
  },
  goal: {
    CREATE_SUCCESS: 'Goal successfully added',
    CREATE_ERROR: 'Error occurred while creating goal',
    UPDATE_SUCCESS: 'Goal successfully updated',
    UPDATE_ERROR: 'Error occurred while updating goal',
  },
}
