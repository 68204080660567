import React, { useEffect, useState } from 'react'
import { FormInstance, message, Modal, Row, Spin } from 'antd'
import { useHistory } from 'react-router-dom'
import { useDispatch } from 'react-redux'
import { Goal } from 'typings/dataStructures'
import UITexts from 'export/UITexts'
import { goalsActions } from 'store/reducers/goalsReducer'
import ConfiguratorForm, { ConfiguratorFormFields } from './ConfiguratorForm'
import styles from './Configurator.module.css'
import * as api from 'api'
import { CreateGoalReq } from 'api'

const NewGoalWithTemplate = ({ templateId, nameUrl }: { templateId: number; nameUrl?: string }) => {
  const history = useHistory()
  const dispatch = useDispatch()
  const [isLoading, setIsLoading] = useState(false)
  const [fetchingGoal, setFetchingGoal] = useState(true)
  const [template, setTemplate] = useState<Goal | null>(null)

  const onSubmit = async (form: FormInstance<ConfiguratorFormFields>) => {
    setIsLoading(true)
    const values = await form.validateFields().catch(() => null)
    if (!values) {
      setIsLoading(false)
      return
    }
    const goalData: CreateGoalReq = {
      name: values.name,
      payment_plan: values.payment_plan,
      payment_size: values.payment_size,
      initial_amount: values.initial_amount,
      current_amount: values.current_amount,
      target_amount: values.target_amount,
      daily_drawdown: values.daily_drawdown,
      sharpe: values.sharpe,
      risk_level: values.risk_level,
      instruments: values.instruments?.map((e) => ({ instrument: e.id, weight: e.weight, is_fixed: e.is_fixed })) ?? [],
      payments: values.payments.map((p) => ({ date: p.date, amount: p.amount })),

      bound_k: values.bound_k!,
      annual_profit: values.annual_profit!,
      monthly_profit: values.monthly_profit!,
      daily_profit: values.daily_profit!,
      weekly_profit: values.weekly_profit!,
      estimate_date: values.estimate_date!,
    }
    const resp = await api.createGoal(goalData)
    if (resp.data) {
      dispatch(goalsActions.addGoal(resp.data))
      message.success(UITexts.goal.CREATE_SUCCESS)
      history.push('/goals')
    } else {
      message.error(UITexts.goal.CREATE_ERROR)
      setIsLoading(false)
    }
  }

  useEffect(() => {
    api
      .getGoal(templateId)
      .then((resp) => {
        if (resp.data) setTemplate(resp.data)
      })
      .finally(() => setFetchingGoal(false))
  }, [])

  useEffect(() => {
    Modal.success({
      title: 'Congratulations, you have successfully copied goal',
      content: `You copied the goal of the user${
        nameUrl ? ' ' + nameUrl : ''
      }, now you can edit it for yourself and add it to your portfolio.`,
      bodyStyle: { padding: '32px 16px 24px' },
    })
  }, [])

  if (fetchingGoal) {
    return (
      <Row justify={'center'}>
        <Spin />
      </Row>
    )
  }
  if (template === null) return <h3 style={{ color: 'red' }}>Template not found</h3>

  let detailedGoalData = undefined
  if (template.chart !== undefined) {
    const chartData = [] as [number, number][]
    const upData = [] as [number, number][]
    const downData = [] as [number, number][]
    const ranges = [] as [number, number, number][]
    const times = template.times!
    const chart = template.chart!
    const up_bound = template.up_bound!
    const down_bound = template.down_bound!
    times.forEach((ts, i) => {
      chartData.push([ts * 1000, chart[i]])
      upData.push([ts * 1000, up_bound[i]])
      downData.push([ts * 1000, down_bound[i]])
      ranges.push([ts * 1000, down_bound[i], up_bound[i]])
    })
    detailedGoalData = {
      chart: [chartData, ranges],
      payments: [],
    }
  }

  const categories = Array.from(
    new Set(template.instruments.filter((i) => i.instrument.category !== null).map((i) => i.instrument.category!.id)),
  )
  const instruments = template.instruments.map((i) => ({
    name: i.instrument.name,
    instrument: i.instrument.name,
    weight: i.weight,
    id: i.instrument.id,
    is_fixed: i.is_fixed,
  }))
  const initialValues: ConfiguratorFormFields = {
    ...template,
    categories,
    instruments,
    fixed_instruments: instruments.filter((i) => i.is_fixed),
  }

  return (
    <div className={styles.main} key={template.id}>
      <h1 className={styles.title}>Goal configurator</h1>
      <ConfiguratorForm
        detailedGoalData={detailedGoalData}
        onSubmit={onSubmit}
        submitBtnText={'Create'}
        initialValues={initialValues}
        isLoading={isLoading}
      />
    </div>
  )
}

export default NewGoalWithTemplate
