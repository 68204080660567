import React from 'react'
import { Button, Row } from 'antd'
import { useHistory } from 'react-router-dom'
import { PlusOutlined } from '@ant-design/icons'
import FundsSelect from 'ui/customFormInputs/FundSelect'
import { useSelector } from 'store'
import { useDispatch } from 'react-redux'
import { appActions } from 'store/reducers/appReducer'
import Products from './components/Products'
import ProfileEdit from './components/ProfileEdit'
import styles from './Backend.module.css'

const Backend = () => {
  const dispatch = useDispatch()
  const selectedFund = useSelector((store) => store.app.selectedFundId)
  const onSelectFund = (fundId: number) => {
    dispatch(appActions.setSelectedFundId(fundId))
  }
  const history = useHistory()
  return (
    <div>
      <h1>Backend</h1>
      <Row style={{ gap: 10 }}>
        <FundsSelect
          //@ts-ignore
          value={selectedFund}
          //@ts-ignore
          onChange={onSelectFund}
          onlyMy
          onFundsLoad={(funds) => {
            if (funds.length) {
              if (selectedFund === undefined || !funds.map((f) => f.id).includes(selectedFund)) {
                onSelectFund(funds[0].id)
              }
            }
          }}
        />
        <Button icon={<PlusOutlined />} onClick={() => history.push('/add_fund')}>
          Create fund
        </Button>
      </Row>
      {selectedFund !== undefined && (
        <>
          <div>
            {/*<h3>Change fund avatar:</h3>*/}
            {/*<Upload*/}
            {/*  maxCount={1}*/}
            {/*  accept={'image/*'}*/}
            {/*  // action={async (e) => {*/}
            {/*  //   return 'hello'*/}
            {/*  // }}*/}
            {/*  listType="picture-card"*/}
            {/*  onChange={(e) => {*/}
            {/*  }}*/}
            {/*  fileList={[*/}
            {/*    {*/}
            {/*      uid: '-1',*/}
            {/*      name: 'image.png',*/}
            {/*      status: 'done',*/}
            {/*      url: 'https://zos.alipayobjects.com/rmsportal/jkjgkEfvpUPVyRjUImniVslZfWPnJuuZ.png',*/}
            {/*    },*/}
            {/*  ]}*/}
            {/*  // action={(e) => {*/}
            {/*  // }}*/}
            {/*>*/}
            {/*  <div>*/}
            {/*    <PlusOutlined />*/}
            {/*    <div style={{ marginTop: 8 }}>change</div>*/}
            {/*  </div>*/}
            {/*</Upload>*/}
          </div>
          <h2 className={styles.blockTitle}>Edit my fund profile:</h2>
          <ProfileEdit fundId={selectedFund} key={selectedFund + 'prof'} />
          <h2 className={styles.blockTitle}>Products:</h2>
          <Products fundId={selectedFund} key={selectedFund + 'instrs'} />
        </>
      )}
    </div>
  )
}

export default Backend
