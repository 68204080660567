import React, { useEffect } from 'react'
import { Layout } from 'antd'
import Router from './Router'
import HeaderNavBar, { changeTheme } from 'components/HeaderNavBar/HeaderNavBar'
import { store, useSelector } from './store'
import './styles/App.css'
import { ConfigProvider } from 'antd'
import { __VERSION__ } from './api'

// ConfigProvider.config({
//   theme: {
//     primaryColor: '#b88525',
//     errorColor: '#ff0000',
//     successColor: '#00ff00',
//     infoColor: '#ffff00',
//     processingColor: '#00ffff',
//     warningColor: '#e34242',
//   },
// });

const { Content, Footer } = Layout

const App = () => {
  const token = useSelector((store) => store.profile.token)
  const user = useSelector((store) => store.profile.user)
  const themeColor = useSelector((store) => store.app.themeColor)

  useEffect(() => {
    document.body.style.setProperty('--color-primary', themeColor)
  }, [themeColor])
  console.log('App', token, user)
  //@ts-ignore
  if (window.first === undefined) {
    changeTheme(store.getState().app.themeColor)
    //@ts-ignore
    window.first = true
  }

  return (
    <ConfigProvider>
      <Layout className="layout">
        <HeaderNavBar />
        <Content className="layout-content">
          <Router />
        </Content>
        <Footer className="footer" style={{ textAlign: 'center' }}>
          <p style={{ color: '#aaa' }}>version: {__VERSION__}</p>
        </Footer>
      </Layout>
    </ConfigProvider>
  )
}

export default App
