import React, { useEffect, useState } from 'react'
import { Button, Empty, Form, Input, message, Row, Spin } from 'antd'
import { LinkedinFilled, ReloadOutlined } from '@ant-design/icons'
import { Link, useHistory } from 'react-router-dom'
import CategoriesSelect from '../../ui/customFormInputs/CategoriesSelect'
import {
  IndustrySelect,
  SectorContext,
  SectorContextValueType,
  SectorSelect,
  SectorTypeSelect,
} from '../../ui/customFormInputs/FundSelect'
import * as api from 'api'
import { InstrumentProps, InstrumentType, updateInstrument } from 'api'

const AddProduct = () => {
  const [form] = Form.useForm<InstrumentProps>()
  const history = useHistory()
  const onOk = async () => {
    const values = await form.validateFields().catch(() => null)
    if (!values) return
    const resp = await api.updateInstrument(id, {
      name: values.name,
      category: values.category,

      industry: values.industry,
      sector: values.sector,
      sector_type: values.sector_type,
    })
    if (resp.data) {
      message.success('Product successfully added').then(() => history.push('/backend'))
    } else {
      message.error('Error occurred while updating product')
    }
  }
  const [instrument, setInstrument] = useState<InstrumentType | null | undefined>()
  const id = parseInt(new URL(location.href).searchParams.get('id')!)
  useEffect(() => {
    if (!isNaN(id)) {
      getProduct(id)
    }
  }, [id])
  const getProduct = async (id: number) => {
    const resp = await api.getInstrument(id)
    if (resp.data) {
      setInstrument(resp.data)
    } else {
      setInstrument(null)
    }
  }
  if (new URL(location.href).searchParams.get('id') === null)
    return (
      <div
        style={{
          display: 'flex',
          alignItems: 'center',
          justifyContent: 'center',
          height: 400,
          width: '100%',
          flexDirection: 'column',
        }}>
        <Empty description={'Error occurred while loading product'} />
        <Link to={'/backend'}>Back</Link>
      </div>
    )

  const [ctx, setCtx] = useState<SectorContextValueType>({
    secType: instrument?.sector_type?.id,
    sector: instrument?.sector?.id,
    industry: instrument?.industry?.id,
  })
  useEffect(() => {
    setCtx({
      secType: instrument?.sector_type?.id,
      sector: instrument?.sector?.id,
      industry: instrument?.industry?.id,
    })
  }, [instrument])
  if (instrument === undefined)
    return (
      <div style={{ display: 'flex', alignItems: 'center', justifyContent: 'center', height: 400, width: '100%' }}>
        <Spin />
      </div>
    )
  if (instrument === null)
    return (
      <div
        style={{
          display: 'flex',
          alignItems: 'center',
          justifyContent: 'center',
          height: 400,
          width: '100%',
          flexDirection: 'column',
        }}>
        <Empty description={'Error occurred while loading instrument'} />
        <Link to={'/backend'}>Back</Link>
      </div>
    )
  return (
    <div>
      <Link to={'/backend'}>Back</Link>
      <h1 style={{ marginTop: 10 }}>Add new product</h1>
      <Form
        form={form}
        initialValues={{
          ...instrument,
          category: instrument.category?.id,
          sector_type: instrument.sector_type?.id,
          sector: instrument.sector?.id,
          industry: instrument.industry?.id,
        }}>
        <Form.Item name={'name'} label={'Name'}>
          <Input />
        </Form.Item>
        <Form.Item name={'category'} label={'Category'}>
          <CategoriesSelect />
        </Form.Item>
        <SectorContext.Provider value={{ ctx, setCtx: setCtx }}>
          <Form.Item name={'sector_type'} label={'Sector type'}>
            <SectorTypeSelect
              onChange={() => {
                form.setFieldsValue({ sector: undefined, industry: undefined })
              }}
            />
          </Form.Item>
          <Form.Item name={'sector'} label={'Sector'}>
            <SectorSelect
              onChange={() => {
                form.setFieldsValue({ industry: undefined })
              }}
            />
          </Form.Item>
          <Form.Item name={'industry'} label={'Industry'}>
            <IndustrySelect />
          </Form.Item>
        </SectorContext.Provider>

        <Row justify={'end'}>
          <Button type={'primary'} onClick={onOk}>
            Save
          </Button>
        </Row>
      </Form>
    </div>
  )
}

export default AddProduct
