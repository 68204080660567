import styles from '../../pages/Configurator/Configurator.module.css'
import { DollarOutlined } from '@ant-design/icons'
import React from 'react'
import { Table } from 'antd'
import { ColumnsType } from 'antd/lib/table'
type Props = {
  value?: { date: number; amount: number }[]
  // onChange
}
const columns: ColumnsType<{ date: number; amount: number }> = [
  {
    title: 'Date',
    dataIndex: 'date',
    render: (v: number) => new Date(v * 1000).toISOString().split('T')[0],
    sorter: (a, b) => b.date - a.date,
    defaultSortOrder: 'descend',
  },
  { title: 'Deposit', dataIndex: 'amount' },
]
const Payments: React.FC<Props> = ({ value }) => {
  return (
    <div className={styles.paymentBox}>
      <Table
        scroll={{
          y: 600,
        }}
        dataSource={value}
        columns={columns}
        rowKey={'date'}
        pagination={false}
        size={'small'}
        // style={{ height: 600 }}
      />
      {/*{value?.map((p: any, i: any) => {*/}
      {/*  const dtInf = typeof p.date === 'number' ? p.date * 1000 : p.date;*/}
      {/*  const dt = new Date(dtInf).toISOString().split('T')[0];*/}
      {/*  return (*/}
      {/*    // <Popover content={`deposit $${p.amount} at ${dt}`} key={i}>*/}
      {/*    //   <div className={styles.paymentItem}>*/}
      {/*    //     <div style={{ display: 'flex', alignItems: 'center' }}>*/}
      {/*    //       <DollarOutlined />*/}
      {/*    //       <span className={styles.paymentAmount}>{p.amount}</span>*/}
      {/*    //     </div>*/}
      {/*    //     <span style={{ fontSize: 12, color: 'gray', whiteSpace: 'nowrap', marginLeft: '4px' }}>{dt}</span>*/}
      {/*    //   </div>*/}
      {/*    // </Popover>*/}

      {/*    <div className={styles.paymentItem} key={i}>*/}
      {/*      <div style={{ display: 'flex', alignItems: 'center' }}>*/}
      {/*        <DollarOutlined />*/}
      {/*        <span className={styles.paymentAmount}>{p.amount}</span>*/}
      {/*      </div>*/}
      {/*      <span style={{ fontSize: 12, color: 'gray', whiteSpace: 'nowrap', marginLeft: '4px' }}>{dt}</span>*/}
      {/*    </div>*/}
      {/*  );*/}
      {/*})}*/}
    </div>
  )
}

export default Payments
