import React, { useEffect, useState } from 'react'
import { Select } from 'antd'

import * as api from 'api'

const PaymentPlanSelect = (props: { onChange?: () => any; value?: string; className?: string }) => {
  const [options, setOptions] = useState<string[]>([])
  const [loading, setLoading] = useState(false)

  const getOptions = async () => {
    setLoading(true)
    const resp = await api.getPaymentPlans()
    if (resp.data) {
      setOptions(resp.data)
    } else {
    }
    setLoading(false)
  }

  useEffect(() => {
    getOptions()
  }, [])

  return (
    <Select
      style={{ display: 'block' }}
      className={props.className}
      loading={loading}
      options={options.map((o) => ({ value: o, label: o }))}
      value={props.value}
      onChange={props.onChange}
    />
  )
}

export default PaymentPlanSelect
