import React, { useState } from 'react'
import { Form } from 'antd'
import styles from './FundsChart.module.css'
import classNames from 'classnames'
import FormValueString from '../../../ui/customFormInputs/FormValueString'
import PortfolioChart from '../../../ui/customFormInputs/PortfolioChart/PortfolioChart'
import { number2currency } from '../../../utils'

const FormItemCurrencyValue = ({ value, onChange, fractionDigits = 0 }: any) => {
  return <p>{number2currency(value, fractionDigits)}</p>
}

const FormItemPercentValue = ({ value, onChange, fractionDigits = 2, multiplier = 1 }: any) => {
  return <p>{value ? (value * multiplier).toFixed(fractionDigits) + '%' : '-'}</p>
}

type Props = {
  className?: string
}

const FundsChart = ({ className }: Props) => {
  const [seeAll, setSeeAll] = useState(false)
  const debugDisplay = 'none'

  return (
    <div className={classNames(styles.container, className)}>
      <button
        className={classNames(styles.seeAllBtn, seeAll && styles.seeAllBtnActive)}
        onClick={() => setSeeAll(!seeAll)}>
        See more info
      </button>
      <div className={!seeAll ? styles.moreInfoContainer : ''}>
        <div className={styles.chartInfoContainer}>
          <Form.Item
            name={'growth_amount'}
            label={'Growth amount'}
            tooltip={
              <div>
                <p className={styles.infoText}>
                  Growth rate is the amount in which the value of an investment, asset, portfolio or business increases
                  over a specific period.
                </p>
              </div>
            }>
            <FormItemCurrencyValue />
          </Form.Item>

          <Form.Item
            name={'paid_amount'}
            label={'Paid amount'}
            tooltip={
              <div>
                <p className={styles.infoText}>
                  Paid amount out - the amount of money that has been paid out for the entire time
                </p>
              </div>
            }>
            <FormItemCurrencyValue />
          </Form.Item>

          <Form.Item
            name={'annual_profit'}
            label={'Annual profit'}
            rules={[{ required: true, message: 'Field is required' }]}
            tooltip={
              <div>
                <p className={styles.infoText}>Annual profit - profit ratio for 1 year</p>
              </div>
            }>
            <FormItemPercentValue fractionDigits={2} multiplier={100} />
          </Form.Item>

          <Form.Item
            style={{ display: debugDisplay }}
            name={'weekly_profit'}
            label={'Weekly profit'}
            rules={[{ required: true, message: 'Field is required' }]}>
            <FormValueString />
          </Form.Item>

          <Form.Item
            style={{ display: debugDisplay }}
            name={'bound_k'}
            label={'bound_k'}
            rules={[{ required: true, message: 'Field is required' }]}>
            <FormValueString />
          </Form.Item>

          <Form.Item
            style={{ display: debugDisplay }}
            name={'annual_profit'}
            label={'annual_profit'}
            rules={[{ required: true, message: 'Field is required' }]}>
            <FormValueString />
          </Form.Item>

          <Form.Item
            style={{ display: debugDisplay }}
            name={'monthly_profit'}
            label={'monthly_profit'}
            rules={[{ required: true, message: 'Field is required' }]}>
            <FormValueString />
          </Form.Item>

          <Form.Item
            style={{ display: debugDisplay }}
            name={'daily_profit'}
            label={'daily_profit'}
            rules={[{ required: true, message: 'Field is required' }]}>
            <FormValueString />
          </Form.Item>

          <Form.Item
            style={{ display: debugDisplay }}
            name={'estimate_date'}
            label={'estimate_date'}
            rules={[{ required: true, message: 'Field is required' }]}>
            <FormValueString />
          </Form.Item>
        </div>
        <Form.Item name={'instruments'} label={'Portfolio'} className={styles.portfolioContainer}>
          <PortfolioChart />
        </Form.Item>
      </div>
    </div>
  )
}

export default FundsChart
