import React, { useEffect, useRef, useState } from 'react'
import { Button, Col, ConfigProvider, Dropdown, Layout, Menu, Modal, Switch } from 'antd'
import { Link, useHistory, useLocation } from 'react-router-dom'
import Logo from 'img/logo.png'
import { store, useSelector } from 'store'
import styles from './HeaderNavBar.module.css'
import { MenuOutlined, UserOutlined } from '@ant-design/icons'
import { ChromePicker, ColorChangeHandler } from 'react-color'
import { useDispatch } from 'react-redux'
import { appActions } from 'store/reducers/appReducer'
import { profileActions, USER_TYPE } from 'store/reducers/profileReducer'

const { Header } = Layout

const getSelectedKeys = (pathname: string) => {
  switch (pathname) {
    case '/':
      return ['home']
    case '/goals':
      return ['goals']
    case '/backend':
      return ['backend']
    default:
      return []
  }
}

export const changeTheme = (c: string) => {
  ConfigProvider.config({
    theme: {
      primaryColor: c,
      // primaryColor: '#008300',
      // errorColor: '#ff0000',
      // successColor: '#00ff00',
      // infoColor: '#ffff00',
      // processingColor: '#00ffff',
      // warningColor: '#e34242',
    },
  })
}

const ColorPicker = (props: { value?: string; onChange?: (arg: string) => void }) => {
  const [color, setColor] = useState<string>(props.value ?? '#1890ff')
  const onChange: ColorChangeHandler = (e) => {
    setColor(e.hex)
    props.onChange?.(e.hex)
  }
  return (
    // <BlockPicker
    //   colors={['#1890ff', '#ff8a65', '#0aa679', '#7546c9', '#f43e53']}
    //   color={props.value ?? color}
    //   onChangeComplete={(e) => {
    //     setColor(e.hex);
    //     props.onChange?.(e.hex);
    //   }}
    // />
    <ChromePicker
      color={color}
      onChange={onChange}
      // onChangeComplete={(e) => {
      //   setColor(e.hex);
      //   props.onChange?.(e.hex);
      // }}
    />
  )
}

const ThemeColorPicker = () => {
  const dispatch = useDispatch()
  const color = useSelector((store) => store.app.themeColor)

  useEffect(() => {
    changeTheme(color)
  }, [color])

  return (
    <ColorPicker
      value={color}
      onChange={(e) => {
        dispatch(appActions.setThemeColor(e))
      }}
    />
  )
}

const UserMenu = () => {
  const dispatch = useDispatch()
  const color = useSelector((store) => store.app.themeColor)
  const [showThemeModal, setShowThemeModal] = useState(false)
  const selectedColor = useRef('#1890ff')

  const onResetTheme = () => {
    store.dispatch(appActions.setThemeColor('#1890ff'))
    closeModal()
  }

  const onColorChange = (color: string) => (selectedColor.current = color)
  const onSaveTheme = () => {
    dispatch(appActions.setThemeColor(selectedColor.current))
    changeTheme(selectedColor.current)
    closeModal()
  }
  const closeModal = () => {
    setShowThemeModal(false)
  }

  const clickHandler = (e: any) => {
    switch (e.key) {
      case 'theme':
        setShowThemeModal(true)
        break
      case 'logout':
        dispatch(profileActions.logout())
        break
      default:
        break
    }
  }

  return (
    <>
      <Menu mode="vertical" className={styles.userMenu} onClick={clickHandler} selectedKeys={[]}>
        <Menu.Item key={'theme'}>Theme</Menu.Item>
        <Menu.Item key={'logout'}>Logout</Menu.Item>
      </Menu>
      <Modal
        visible={showThemeModal}
        closable={false}
        width={225}
        maskStyle={{ background: 'rgba(0, 0, 0, 0.2)' }}
        onCancel={() => setShowThemeModal(false)}
        footer={
          <>
            <Button onClick={onResetTheme}>Reset</Button>
            <Button type="primary" onClick={onSaveTheme}>
              OK
            </Button>
          </>
        }>
        <ColorPicker onChange={onColorChange} value={color} />
      </Modal>
    </>
  )
}

const SwitchUserType = () => {
  const userType = useSelector((store) => store.profile.user?.userType)
  const dispatch = useDispatch()
  const onChange = (e: boolean) => {
    dispatch(profileActions.setUserType(e ? USER_TYPE.FUND : USER_TYPE.CUSTOMER))
  }
  return (
    <div style={{ display: 'flex' }}>
      <span>customer</span>
      <Switch checked={userType === USER_TYPE.FUND} onChange={onChange} />
      <span>fund</span>
    </div>
  )
}

const HeaderNavBar = () => {
  const history = useHistory()
  const profile = useSelector((store) => store.profile)
  const isAuth = profile.token !== undefined
  const username = useSelector((state) => state.profile.user?.username)
  const location = useLocation()
  const selectedKeys = location.search !== '' ? [] : getSelectedKeys(location.pathname)
  const showLoginBtn = !(isAuth || /(login)|(register)/.test(location.pathname))
  const MenuItems = () => {
    return (
      <Menu.Item key={'goals'}>
        <Link to="/goals">Goals</Link>
      </Menu.Item>
    )
  }
  return (
    <Header className={styles.header}>
      <div className={styles.headerContent}>
        <Link to="/" className={styles.logoLink}>
          <img className={styles.logo} src={Logo} alt={''} />
        </Link>
        {isAuth && (
          <Col xl={12} lg={12} md={12} sm={12} xs={4} style={{ marginRight: 'auto', height: '100%' }}>
            <Menu
              // className={styles.menuContainer}
              mode="horizontal"
              triggerSubMenuAction={'click'}
              style={{ height: '100%' }}
              // selectedKeys={selectedKeys}
              overflowedIndicator={<MenuOutlined style={{ fontSize: 16 }} />}>
              <Menu.Item key={'home'}>
                <Link to="/">Home</Link>
              </Menu.Item>
              {profile.user?.userType === USER_TYPE.CUSTOMER && (
                <Menu.Item key={'goals'}>
                  <Link to="/goals">Goals</Link>
                </Menu.Item>
              )}
              {profile.user?.userType === USER_TYPE.FUND && (
                <Menu.Item key={'backend'}>
                  <Link to="/backend">Profile</Link>
                </Menu.Item>
              )}
              {/* {profile.user?.userType === USER_TYPE.CUSTOMER && (
                <>
                  <Menu.Item key={'home'}>
                    <Link to="/">Home</Link>
                  </Menu.Item>
                  <Menu.Item key={'goals'}>
                    <Link to="/goals">Goals</Link>
                  </Menu.Item>
                </>
              )}
              {profile.user?.userType === USER_TYPE.FUND && (
                <>
                  <Menu.Item key={'home'}>
                    <Link to="/">Home</Link>
                  </Menu.Item>
                  <Menu.Item key={'backend'}>
                    <Link to="/backend">Profile</Link>
                  </Menu.Item>
                </>
              )} */}
            </Menu>
          </Col>
        )}
        {showLoginBtn && (
          <>
            <Button type="primary" className={styles.loginBtn} onClick={() => history.push('/login')}>
              Log in
            </Button>
            <Button type="primary" className={styles.loginBtn} onClick={() => history.push('/manager_login')}>
              Manager
            </Button>
          </>
        )}
        {/*{isAuth && <SwitchUserType />}*/}
        {isAuth && (
          <Dropdown overlay={<UserMenu />} placement="bottomRight">
            <button className={styles.userMenuBtn}>
              <p className={styles.username}>{username}</p>
              <UserOutlined style={{ marginLeft: '8px', fontSize: 16 }} />
            </button>
          </Dropdown>
        )}
      </div>
    </Header>
  )
}

export default HeaderNavBar
