import React, { useEffect, useRef } from 'react'
import Highcharts from 'highcharts/highstock'

require('highcharts/modules/boost')(Highcharts)
require('highcharts/highcharts-more')(Highcharts) // for arearange
import HighchartsReact from 'highcharts-react-official'
import { HighChartsRef } from '../../../utils/hc-utils'
import { useSelector } from 'store'

type Props = {
  data: [number, number][]
  loading?: boolean
}
const Chart: React.FC<Props> = (props) => {
  const themeColor = useSelector((store) => store.app.themeColor)

  const ref = useRef<HighChartsRef>()
  useEffect(() => {
    if (ref.current) {
      if (props.loading === true) {
        ref.current.chart.showLoading()
      }
      if (props.loading === false) {
        ref.current.chart.hideLoading()
      }
    }
  }, [props.loading, ref.current])
  const options: Highcharts.Options = {
    time: { useUTC: false },

    chart: {
      animation: {
        duration: 0,
      },
      zoomType: 'x',
      type: 'line',
      reflow: true,
      height: 260,
      resetZoomButton: {
        position: {
          align: 'left',
          verticalAlign: 'top',
          y: -4,
          x: -2,
        },
      },
    },
    title: { text: '' },
    scrollbar: { enabled: false },
    rangeSelector: { enabled: false },
    navigator: { enabled: false },
    xAxis: {
      type: 'datetime',
      ordinal: false,
    },
    yAxis: {
      labels: { align: 'left' },
      //@ts-ignore
      title: { enabled: false },
    },
    tooltip: {
      valueDecimals: 1,
    },
    plotOptions: {
      spline: {
        states: {
          hover: {
            animation: {
              duration: 0,
            },
            enabled: true,
            lineWidth: 1,
          },
        },
      },
    },
    series: [
      {
        type: 'line',
        name: 'chart',
        data: props.data,
        lineWidth: 1,
        id: 'lineser',
        states: { hover: { lineWidth: 1, opacity: 1 } },
        color: themeColor,
      },
    ],
  }

  return (
    <HighchartsReact
      //@ts-ignore
      ref={ref}
      highcharts={Highcharts}
      options={options}
      constructorType={'stockChart'}
      // constructorType={'chart'}
      style={{ width: '100%' }}
    />
  )
}

export default Chart
