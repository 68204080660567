import React, { useEffect, useState } from 'react'
import { Select } from 'antd'
import { Category } from 'typings/dataStructures'
import * as api from 'api'

type Props = {
  onChange?: (arg: number[]) => void
  value?: number[]
  mode?: 'multiple'
  className?: string
}

const CategoriesSelect: React.FC<Props> = (props) => {
  const [categories, setCategories] = useState<Category[]>()
  const [loading, setLoading] = useState(false)

  const getCategories = async () => {
    setLoading(true)
    const resp = await api.getCategories().catch(() => null)

    if (resp) {
      setCategories(resp.data)
    }
    setLoading(false)
  }

  useEffect(() => {
    getCategories()
  }, [])

  return (
    <Select
      style={{ display: 'block' }}
      className={props.className}
      loading={loading}
      placeholder={'Select categories'}
      mode={props.mode}
      value={props.value}
      onChange={props.onChange}
      // loading={!categories}
      options={categories?.map((c) => ({ label: c.name, value: c.id }))}
      filterOption={(input, option) => option!.label.toLowerCase().indexOf(input.toLowerCase()) >= 0}
    />
  )
}

export default CategoriesSelect
