import React, { useEffect, useState } from 'react'
import { InstrumentType } from 'api'
import * as api from 'api'
import { Button, InputNumber, Row, Select } from 'antd'
import { DeleteOutlined } from '@ant-design/icons'
import styles from './InstrumentWeightSelect.module.css'
import SliderInput from '../SliderInput/SliderInput'

type ConfiguratorInstrument = { instrument: string; weight: number; id: number; is_fixed: boolean }
type InstrumentSelectProps = {
  value?: ConfiguratorInstrument[]
  onChange?: (arg: ConfiguratorInstrument[]) => void
  onOk?: () => void
}
const InstrumentWeightSelect: React.FC<InstrumentSelectProps> = ({ onChange, value, onOk }) => {
  const [instruments, setInstruments] = useState<InstrumentType[]>()
  const options = instruments
    ?.map((i) => ({
      label: [i.source, i.sector_type?.name, i.sector?.name, i.industry?.name, i.name].filter(Boolean).join('>'),
      // label: `${i.sector_type?.name} ${i.name}`,
      value: i.id,
    }))
    .filter((i) => !(value ?? []).map((v) => v.id).includes(i.value))
  const [selectedInstrument, setSelectedInstrument] = useState<number>()
  useEffect(() => {
    getInstruments()
  }, [])
  const getInstruments = async () => {
    const resp = await api.getInstruments()
    if (resp.data) {
      setInstruments(resp.data)
    }
  }

  // const instrumentsToOptions = (data: InstrumentType[]): any => {
  //   const secTypes = data.map(d=>d.sector_type?.name).filter(e=>e)
  //   if (data && Object.keys(data).length === 1 && Object.keys(data)[0] === '') {
  //     data = data[''];
  //   }
  //   const getLabel = (title: string) => (title === 'Undefined' ? 'No name' : title);
  //   if (!data) return [];
  //   const result = [];
  //   if (Array.isArray(data)) {
  //     data.forEach((title) => {
  //       result.push({ value: title, label: getLabel(title) });
  //     });
  //   } else {
  //     for (const [k, v] of Object.entries(data)) {
  //       result.push({ value: k, label: getLabel(k), children: instrumentsToOptions(v) });
  //     }
  //   }
  //   return result;
  // };

  return (
    <div style={{ width: '100%', display: 'block', minWidth: 270 }}>
      <div className={styles.selectBox}>
        <Select
          showSearch
          dropdownMatchSelectWidth={276}
          filterOption={(input, option) => {
            if (!option) return false
            return (
              option.label.toLowerCase().indexOf(input.toLowerCase()) >= 0 ||
              option.value?.toString().toLowerCase().indexOf(input.toLowerCase()) >= 0
            )
          }}
          size={'small'}
          className={styles.select}
          options={options}
          value={selectedInstrument}
          onChange={setSelectedInstrument}
        />

        {/*<Cascader*/}
        {/*  value={value}*/}
        {/*  style={{ width: 200 }}*/}
        {/*  options={instrumentsToOptions(instruments)}*/}
        {/*  placeholder="Select instrument"*/}
        {/*  onChange={onChange}*/}
        {/*  //@ts-ignore*/}
        {/*  showSearch={{ filter, render: searchRender, matchInputWidth: false }}*/}
        {/*  displayRender={displayRender}*/}
        {/*  popupPlacement={'bottomLeft'}*/}
        {/*/>*/}

        <Button
          disabled={selectedInstrument === undefined}
          size={'small'}
          type={'primary'}
          onClick={() => {
            if (selectedInstrument !== undefined) {
              onChange?.([
                ...(value?.filter((v) => v.id !== selectedInstrument) ?? []),
                {
                  id: selectedInstrument,
                  is_fixed: true,
                  weight: 0,
                  instrument: instruments?.find((i) => i.id === selectedInstrument)?.name ?? selectedInstrument + '',
                },
              ])
            }
          }}>
          Add
        </Button>
      </div>
      {value
        ?.sort((a, b) => b.instrument.localeCompare(a.instrument))
        .map((e) => (
          <div key={e.id} className={styles.item}>
            <div style={{ position: 'relative', paddingRight: 20, marginBottom: 4 }}>
              <span className={styles.item__name} title={e.instrument}>
                {e.instrument}
              </span>
              <DeleteOutlined
                className={styles.delete}
                onClick={() => {
                  onChange?.([...(value?.filter((v) => v.instrument !== e.instrument) ?? [])])
                }}
              />
            </div>
            <div style={{ display: 'block' }}>
              <SliderInput
                min={0}
                max={1}
                step={0.01}
                value={e.weight}
                onChange={(vv) => {
                  onChange?.([
                    ...(value.filter((v) => v.id! !== e.id!) ?? []),
                    {
                      id: e.id,
                      is_fixed: true,
                      weight: vv as number,
                      instrument: e.instrument,
                    },
                  ])
                }}
              />
            </div>
            {/*<InputNumber*/}
            {/*  size={'small'}*/}
            {/*  style={{ width: 80 }}*/}
            {/*  value={e.weight}*/}
            {/*  step={0.01}*/}
            {/*  onChange={(vv) => {*/}
            {/*    onChange?.([*/}
            {/*      ...(value.filter((v) => v.id! !== e.id!) ?? []),*/}
            {/*      {*/}
            {/*        id: e.id,*/}
            {/*        is_fixed: true,*/}
            {/*        weight: vv,*/}
            {/*        instrument: e.instrument,*/}
            {/*      },*/}
            {/*    ])*/}
            {/*  }}*/}
            {/*/>*/}
          </div>
        ))}
      <Row justify={'end'}>
        <Button type={'primary'} size={'small'} onClick={onOk} style={{ width: 42 }}>
          Ok
        </Button>
      </Row>
    </div>
  )
}

export default InstrumentWeightSelect
