import { applyMiddleware, combineReducers, createStore } from 'redux'
import thunk from 'redux-thunk'
import { persistStore, persistReducer } from 'redux-persist'
import persistStorage from 'redux-persist/lib/storage'
import { composeWithDevTools } from 'redux-devtools-extension/logOnlyInProduction'
import { TypedUseSelectorHook, useSelector as useReduxSelector } from 'react-redux'
import createSagaMiddleware from 'redux-saga'
import { appReducer } from './reducers/appReducer'
import { profileReducer } from './reducers/profileReducer'
import { goalsReducer } from './reducers/goalsReducer'
import sagas from './sagas'

const persistConfig = {
  key: 'root',
  storage: persistStorage,
  whitelist: ['profile', 'app'],
  // whitelist: [],
  // blacklist: ['', 'app'],
  // debug: false,
  // debug: __DEV__,
  debug: process.env.NODE_ENV === 'development',
}

const middleware = []
const sagaMiddleware = createSagaMiddleware()
middleware.push(sagaMiddleware)
middleware.push(thunk)

export const rootReducer = combineReducers({
  app: appReducer,
  profile: profileReducer,
  goals: goalsReducer,
})
export type RootState = ReturnType<typeof rootReducer>

const persistedReducer = persistReducer<RootState>(persistConfig, rootReducer)
export const useSelector: TypedUseSelectorHook<RootState> = useReduxSelector
export const store = createStore(persistedReducer, composeWithDevTools(applyMiddleware(...middleware)))
sagaMiddleware.run(sagas)
export const persistor = persistStore(store)
