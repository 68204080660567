import React, { useState } from 'react'
import styles from './GoalCard.module.css'
import { Goal, Payment } from '../../typings/dataStructures'
import { ColumnsType, SortOrder } from 'antd/es/table/interface'
import checkIcon from '../../img/check.svg'
import crossIcon from '../../img/cross.svg'
import { Button, Modal, Row, Table } from 'antd'
import { number2currency } from '../../utils'
import { useHistory } from 'react-router-dom'
import { deleteGoal } from '../../store/reducers/goalsReducer'
import { DeleteOutlined } from '@ant-design/icons'
import ShareGoalModal from '../ShareGoalModal/ShareGoalModal'

const paymentColumns: ColumnsType<Payment> = [
  {
    title: 'Amount',
    dataIndex: 'amount',
    key: 'amount',
    sorter: (a, b) => a.amount - b.amount,
  },
  {
    title: 'Date',
    dataIndex: 'date',
    key: 'date',
    defaultSortOrder: 'descend',
    sorter: (a, b) => b.date - a.date,
    render(v) {
      return new Date(v * 1000).toISOString().split('T')[0]
    },
  },
  {
    title: 'Completed',
    dataIndex: 'completed',
    key: 'completed',
    render: (value: boolean) => <img src={value ? checkIcon : crossIcon} alt={value ? 'Yes' : 'No'} />,
  },
]

const GoalCard = ({ goal }: { goal: Goal }) => {
  const history = useHistory()
  const [showPayments, setShowPayments] = useState(false)
  const [showShareModal, setShowShareModal] = useState(false)
  const progress = Math.ceil((goal.current_amount / goal.target_amount) * 100) + '%'
  const background = `linear-gradient(to right, green 0%, green ${progress}, transparent ${progress}, transparent 100%)`

  const onDeleteClick = (e: React.MouseEvent<HTMLButtonElement, MouseEvent>) => {
    e.stopPropagation()
    deleteGoal(goal.id)
  }

  const onShareClick = (e: React.MouseEvent<HTMLButtonElement, MouseEvent>) => {
    e.stopPropagation()
    setShowShareModal(true)
  }

  const onPaymentsClick = (e: React.MouseEvent<HTMLButtonElement, MouseEvent>) => {
    e.stopPropagation()
    setShowPayments(!showPayments)
  }

  return (
    <>
      <div className={styles.container} onClick={() => history.push('/configurator?id=' + goal.id)}>
        <DeleteOutlined className={styles.deleteGoalBtn} onClick={onDeleteClick} />
        <h4 className={styles.title}>{goal.name}</h4>
        <span onClick={onShareClick} style={{ color: 'dodgerblue' }}>
          share
        </span>
        {/* <a href={'tel:+79998887766'}>+7(999)888-77-66</a> */}
        <div className={styles.progressBarContainer}>
          <span>{number2currency(goal.current_amount)}</span>
          <div className={styles.progressBar} style={{ background: background }} />
          <span>{number2currency(goal.target_amount)}</span>
        </div>
        <div className={styles.paymentRow}>
          <p>
            Payment: {number2currency(goal.payment_size)} / {goal.payment_plan}
          </p>
          {goal.payments.length > 0 && (
            <button onClick={onPaymentsClick} className={styles.paymentsBtn}>
              history
            </button>
          )}
        </div>
      </div>
      <Modal
        title="Payments"
        visible={showPayments}
        onCancel={() => setShowPayments((s) => !s)}
        footer={null}
        className={styles.goalCardPaymentModal}>
        <Table size={'small'} dataSource={goal.payments} columns={paymentColumns} pagination={false} rowKey={'id'} />
      </Modal>
      <Modal
        title={`Share ${goal.name} goal`}
        visible={showShareModal}
        onCancel={() => setShowShareModal(false)}
        footer={null}
        className={styles.goalCardPaymentModal}>
        <ShareGoalModal goal={goal} />
      </Modal>
    </>
  )
}

export default GoalCard
