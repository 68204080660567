import React from 'react'
import { Button, Form, Input, message } from 'antd'

import * as api from 'api'
import { store } from 'store'
import { profileActions, USER_TYPE } from 'store/reducers/profileReducer'

import { Link, useHistory } from 'react-router-dom'

import styles from './Login.module.css'

type RegisterForm = {
  email: string

  first_name: string
  last_name: string

  password: string
  confirm: string
}

const Register = () => {
  const [form] = Form.useForm<RegisterForm>()
  const history = useHistory()

  const onFinish = async ({ email, first_name, last_name, password }: RegisterForm) => {
    const data = { email, first_name, last_name, password }
    const resp = await api.register(data)
    if (resp.data) {
      const { username, token, id } = resp.data
      //TODO: retrieve userType from backend
      store.dispatch(
        profileActions.setTokenAndUser({
          token,
          user: {
            id,
            username,
            userType: USER_TYPE.CUSTOMER,
          },
        }),
      )
      history.push('/')
    } else {
      message.error('Invalid credentials')
    }
  }

  return (
    <div className={styles.container}>
      <Form
        form={form}
        className={styles.form}
        onFinish={onFinish}
        layout="vertical"
        name="register"
        initialValues={{ first_name: '', last_name: '' }}
        scrollToFirstError>
        <Form.Item
          name="email"
          label="E-mail"
          rules={[
            {
              type: 'email',
              message: 'The input is not valid E-mail!',
            },
            {
              required: true,
              message: 'Please input your E-mail!',
            },
          ]}>
          <Input placeholder="E-mail" />
        </Form.Item>
        <Form.Item
          name="password"
          label="Password"
          rules={[
            {
              required: true,
              message: 'Please input your password!',
            },
          ]}
          hasFeedback>
          <Input.Password placeholder="Password" />
        </Form.Item>
        <Form.Item
          name="confirm"
          label="Confirm Password"
          dependencies={['password']}
          hasFeedback
          rules={[
            {
              required: true,
              message: 'Please confirm your password!',
            },
            ({ getFieldValue }) => ({
              validator(_, value) {
                if (!value || getFieldValue('password') === value) {
                  return Promise.resolve()
                }
                return Promise.reject(new Error('The two passwords that you entered do not match!'))
              },
            }),
          ]}>
          <Input.Password placeholder="Confirm Password" />
        </Form.Item>

        <Form.Item
          name="first_name"
          label="First name"
          rules={[{ required: true, message: 'Please input your first name!' }]}>
          <Input />
        </Form.Item>

        <Form.Item
          name="last_name"
          label="Last name"
          rules={[{ required: true, message: 'Please input your last name!' }]}>
          <Input />
        </Form.Item>

        <Form.Item>
          <Button className={styles.login} type="primary" htmlType="submit">
            Register
          </Button>
          Or <Link to={'login'}>login</Link>
        </Form.Item>
      </Form>
    </div>
  )
}

export default Register
