import React from 'react'
import { Button, Checkbox, Form, Input, message } from 'antd'

import { store } from 'store'
import { profileActions, USER_TYPE } from 'store/reducers/profileReducer'
import styles from './Login.module.css'
import * as api from 'api'
import { LockOutlined, UserOutlined } from '@ant-design/icons'
import { Link, useHistory } from 'react-router-dom'

type LoginForm = {
  username: string
  password: string
}

const Login = () => {
  const [form] = Form.useForm<LoginForm>()
  const history = useHistory()
  const is_manager = location.href.includes('manager')

  const onFinish = async (values: LoginForm) => {
    const { username, password } = values
    const resp = await api.login(username, password)
    if (!resp) {
      message.error('Invalid credentials')
      return
    }
    if (resp.data) {
      //@ts-ignore
      if (resp.data.error) {
        message.error('Invalid credentials')
      } else {
        const { username, token, id, last_name, first_name } = resp.data

        store.dispatch(
          profileActions.setTokenAndUser({
            user: { id, username, userType: is_manager ? USER_TYPE.FUND : USER_TYPE.CUSTOMER, last_name, first_name },
            token: token,
          }),
        )
        window.location.reload()
        // history.push(is_manager ? '/backend' : '/')
      }
    } else {
      message.error('Invalid credentials')
    }
  }

  const onFinishFailed = () => {}

  return (
    <div className={styles.container}>
      <Form
        form={form}
        className={styles.form}
        name="basic"
        initialValues={{ remember: true }}
        onFinish={onFinish}
        onFinishFailed={onFinishFailed}>
        {is_manager && <h2>Fund manager login</h2>}
        <Form.Item
          className={styles.form__item}
          name="username"
          rules={[{ required: true, message: 'Please input your username!' }]}>
          <Input autoComplete="on" prefix={<UserOutlined className="site-form-item-icon" />} placeholder="Username" />
        </Form.Item>
        <Form.Item name="password" rules={[{ required: true, message: 'Please input your password!' }]}>
          <Input.Password
            autoComplete="on"
            prefix={<LockOutlined className="site-form-item-icon" />}
            placeholder="Password"
          />
        </Form.Item>
        <div style={{ marginBottom: 20 }}>
          <Form.Item name="remember" valuePropName="checked" noStyle>
            <Checkbox>Remember me</Checkbox>
          </Form.Item>
          {/*<a className={styles.forgot} href={'#'}>*/}
          {/*  Forgot password*/}
          {/*</a>*/}
        </div>
        <Form.Item>
          <Button className={styles.login} type="primary" htmlType="submit">
            Log in
          </Button>
          {!is_manager && (
            <>
              Or <Link to={'register'}>register now!</Link>
            </>
          )}
        </Form.Item>
      </Form>
    </div>
  )
}

export default Login
