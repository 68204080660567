import React, { useEffect, useRef } from 'react'
import { Layout, Row } from 'antd'
import styles from './Home.module.css'
import classNames from 'classnames'
import img_1 from 'img/pictures/1.png'
import img_1_sm from 'img/pictures/1_sm.png'
import img_2 from 'img/pictures/4.png'
import img_2_sm from 'img/pictures/4_sm.png'
import img_2_1 from 'img/pictures/6.png'
import img_3 from 'img/pictures/7.png'
import img_3_sm from 'img/pictures/7_sm.png'
import { LazyLoadImage } from 'react-lazy-load-image-component'

const Coin = ({ className }: { className?: string }) => {
  return (
    <div className={classNames(styles.coinContainer, className)}>
      <div className={styles.coin}>
        <div className={styles.oval_back}>
          <div className={styles.inner_oval} />
        </div>
      </div>
    </div>
  )
}

const Home = () => {
  const botElRef = useRef<HTMLDivElement>()

  useEffect(() => {
    const observer = new IntersectionObserver(
      (e) => {
        if (e[0].isIntersecting) {
          botElRef.current!.classList.add(styles.block3Anim)
          observer.unobserve(botElRef.current!)
        }
      },
      { threshold: 0.3 },
    )
    observer.observe(botElRef.current!)
  }, [])

  return (
    <div className={styles.container}>
      <h1 className={styles.title}>Relax while we make you money</h1>
      <div className={classNames(styles.block, styles.block1)}>
        <div className={styles.blockImg}>
          <img src={img_1} alt="" />
          <Coin className={styles.coin1} />
          <Coin className={styles.coin2} />
          <Coin className={styles.coin3} />
        </div>
        <div className={styles.blockTextContainer}>
          <h2 className={styles.blockTitle}>
            Investing is easy
            <br />
            when it’s automated.
          </h2>
          <p className={styles.blockText}>
            Regardless of whether you create your first briefcase or are looking for complex strategies that will help
            maximize profits and minimize taxes, our automated investment will help you get more for the smallest time.
          </p>
        </div>
      </div>

      <div className={classNames(styles.block, styles.block2)}>
        <img className={styles.blockImg} src={img_2} alt="" />
        <div className={styles.blockTextContainer}>
          <h2 className={styles.blockTitle}>Every day is intended for the construction of long-term wealth.</h2>
          <p className={styles.blockText}>
            Our cash account allows you to automate your savings. You can pay bills and invest additional cash in
            seconds to save your future.
          </p>
        </div>
      </div>

      {/* @ts-ignore */}
      <div className={classNames(styles.block, styles.block3)} ref={botElRef}>
        <div className={styles.blockTextContainer}>
          <h2 className={styles.blockTitle}>Not really a bank. Much better.</h2>
          <p className={styles.blockText}>
            We cooperate with banks to offer incredible banking services, such as insurance, fastest and convenient
            support, as well as safe. We will make you rich!
          </p>
        </div>
        <img className={styles.blockImg} src={img_3} alt="" />
      </div>
    </div>
  )
}

export default Home
