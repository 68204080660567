import React from 'react'
import moment from 'moment'
import { CheckOutlined, CloseOutlined } from '@ant-design/icons'
import { message } from 'antd'

import { ComponentInputType } from '../typings/dataStructures'

export const dateFormatter = 'DD.MM.YYYY HH:mm'
export const prettyDate = (ts) => moment(ts * 1000).format(dateFormatter)
export const toIsoString = (date) => {
  var tzo = -0,
    dif = tzo >= 0 ? '+' : '-',
    pad = function (num) {
      var norm = Math.floor(Math.abs(num))
      return (norm < 10 ? '0' : '') + norm
    }

  return (
    date.getFullYear() +
    '-' +
    pad(date.getMonth() + 1) +
    '-' +
    pad(date.getDate()) +
    'T' +
    pad(date.getHours()) +
    ':' +
    pad(date.getMinutes()) +
    ':' +
    pad(date.getSeconds()) +
    dif +
    pad(tzo / 60) +
    ':' +
    pad(tzo % 60)
  )
}
export const prettyBool = (bool) =>
  bool ? <CheckOutlined style={{ color: 'green' }} /> : <CloseOutlined style={{ color: 'red' }} />
export const isNullOrUndef = (v) => v === undefined || v === null
export const showErrorsMessage = (errors) => {
  const erroredErrors = Object.entries(errors).filter(([k, v]) => v)
  message.error(
    `${erroredErrors.map(([k, v]) => k).join(', ')} ${erroredErrors.length === 1 ? 'is' : 'are'} not specified`,
  )
}
export const throttler = (timeout) => {
  let int
  return (cb) => {
    int && clearTimeout(int)
    int = setTimeout(() => cb(), timeout)
  }
}
export const clone = (v) => {
  return JSON.parse(JSON.stringify(v))
}
export const mapperToOptions = (mapper) => {
  return Object.entries(mapper).map(([k, v]) => ({ label: v, value: k }))
}

export const ts2ISO = (dt_start_ts, dt_end_ts) => {
  const start = new Date(new Date(dt_start_ts).setUTCHours(0, 0, 0, 0))
  const end = new Date(new Date(dt_end_ts).setUTCHours(23, 59, 59, 999))
  const dt_start = new Date(start.getTime() - start.getTimezoneOffset() * 60000).toISOString()
  const dt_end = new Date(end.getTime() - end.getTimezoneOffset() * 60000).toISOString()

  // const dt_start = new Date(start.getTime()).toISOString();
  // const dt_end = new Date(end.getTime()).toISOString();

  // const dt_start = new Date(new Date(dt_start_ts).setUTCHours(0, 0, 0, 0)).toISOString();
  // const dt_end = new Date(new Date(dt_end_ts).setUTCHours(23, 59, 59, 999)).toISOString();
  return [dt_start, dt_end]
}

export const isEqualArrays = (a, b) => {
  if (!a && !b) {
    return true
  }
  if (a && !b) {
    return false
  }
  if (!a && b) {
    return false
  }

  if (a.length !== b.length) {
    return false
  } else {
    for (let i = 0; i < a.length; i++) {
      if (a[i] !== b[i]) {
        return false
      }
    }

    return true
  }
}

export const number2currency = (number, fractionDigits = 0) => {
  if (number === undefined) return ''
  return new Intl.NumberFormat('en-EN', {
    style: 'currency',
    currency: 'USD',
    minimumFractionDigits: fractionDigits,
    maximumFractionDigits: fractionDigits,
  }).format(number)
}
