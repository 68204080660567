import React, { useEffect, useRef, useState } from 'react'
import { Button, Form, FormInstance, Input, InputNumber, message, Slider } from 'antd'
import classNames from 'classnames'
import * as api from 'api'
import { Goal, Payment } from 'typings/dataStructures'
import CategoriesSelect from 'ui/customFormInputs/CategoriesSelect'
import PaymentPlanSelect from 'ui/customFormInputs/PaymentPlanSelect'
import InstrumentWeightSelect from 'ui/customFormInputs/InstrumentWeightSelect/InstrumentWeightSelect'
import Payments from 'ui/customFormInputs/Payments'
import { isNullOrUndef } from 'utils'
import AccumulationChart from './components/AccumulationChart'
import styles from './Configurator.module.css'
import FundsChart from './components/FundsChart'

const canSimulate = (values: any) => {
  let can = true

  if (isNullOrUndef(values.target_amount)) can = false
  if (isNullOrUndef(values.initial_amount)) can = false
  if (isNullOrUndef(values.payment_plan)) can = false
  if (isNullOrUndef(values.payment_size)) can = false
  if (isNullOrUndef(values.risk_level)) can = false

  if (isNullOrUndef(values.daily_drawdown)) can = false
  if (isNullOrUndef(values.sharpe)) can = false
  return can
}

export type ConfiguratorFormFields = Pick<
  Goal,
  | 'name'
  | 'target_amount'
  | 'initial_amount'
  | 'payment_plan'
  | 'risk_level'
  | 'payment_size'
  | 'current_amount'
  | 'daily_drawdown'
  | 'sharpe'
> & {
  categories: number[]
  payments: Payment[]
  instruments?: { instrument: string; weight: number; id: number; is_fixed: boolean }[]
  growth_amount?: number
  paid_amount?: number
  chart?: any[]

  bound_k?: number
  annual_profit?: number
  monthly_profit?: number
  daily_profit?: number
  weekly_profit?: number
  estimate_date?: number
  fixed_instruments?: { instrument: string; weight: number; id: number; is_fixed: boolean }[]
}

type Props = {
  onSubmit: (form: FormInstance<ConfiguratorFormFields>) => Promise<void>
  submitBtnText?: string
  initialValues: ConfiguratorFormFields
  detailedGoalData?: any
  isLoading?: boolean
}

const ConfiguratorForm: React.FC<Props> = ({
  onSubmit,
  initialValues,
  detailedGoalData = { payments: [], chart: [] },
  ...props
}) => {
  const [form] = Form.useForm<ConfiguratorFormFields>()
  const [chartLoading, setChartLoading] = useState(false)
  const [data, setData] =
    useState<{ chart: [[number, number][], [number, number, number][]]; payments: any[] }>(detailedGoalData)

  const ac = useRef(new AbortController())

  const simulate = async () => {
    const values = form.getFieldsValue() // validateFields().catch(() => null)
    // if (!values) return {}
    if (!canSimulate(values)) {
      message.error('Fill all fields')
      return
    }
    const chartReq = {
      target_amount: values.target_amount,
      initial_amount: values.initial_amount,
      payment_plan: values.payment_plan,
      payment_size: values.payment_size,
      risk_level: values.risk_level,
      daily_drawdown: values.daily_drawdown,
      sharpe: values.sharpe,
      categories: values.categories,
      fixed_instruments: values.fixed_instruments,
    }
    console.log('simulate req:', chartReq)
    ac.current.abort()
    setChartLoading(true)
    const _ac = new AbortController()
    ac.current = _ac
    const resp = await api.doChart(chartReq, ac.current.signal)
    console.log('simulate resp:', resp)
    if (_ac.signal.aborted) {
      return
    }

    if (!resp.data) {
      message.error('Simulate error')
      setChartLoading(false)
      return
    }

    form.setFieldsValue({
      payments: resp.data.payments,
      growth_amount: resp.data.growth_amount,
      paid_amount: resp.data.paid_amount,
      instruments: resp.data.instruments,
      annual_profit: resp.data.annual_profit,

      monthly_profit: resp.data.monthly_profit,
      daily_profit: resp.data.daily_profit,
      weekly_profit: resp.data.weekly_profit,
      bound_k: resp.data.bound_k,
      estimate_date: resp.data.times[resp.data.times.length - 1],
    })
    const chartData = [] as [number, number][]
    const upData = [] as [number, number][]
    const downData = [] as [number, number][]
    const ranges = [] as [number, number, number][]
    const times = resp.data.times as number[]
    const chart = resp.data.chart
    const up_bound = resp.data.up_bound
    const down_bound = resp.data.down_bound

    times.forEach((ts, i) => {
      chartData.push([ts * 1000, chart[i]])
      upData.push([ts * 1000, up_bound[i]])
      downData.push([ts * 1000, down_bound[i]])
      ranges.push([ts * 1000, down_bound[i], up_bound[i]])
    })

    if (resp.data) {
      setData({
        chart: [chartData, ranges],
        payments: resp.data.payments.map((p: any) => ({ x: p.date * 1000, title: 'payment: ' + p.amount })),
      })
    }
    setChartLoading(false)
  }

  useEffect(() => {
    console.log(detailedGoalData)
    if (detailedGoalData.chart.length === 0) {
      console.log('simulate')
      simulate()
    }
  }, [])

  return (
    <Form form={form} initialValues={initialValues} className={styles.container} requiredMark={false}>
      <div className={styles.fieldsContainer}>
        <Form.Item
          className={styles.formItem}
          name={'name'}
          label={'Name'}
          rules={[
            () => ({
              validator(_, value) {
                if (value) return Promise.resolve()
                else return Promise.reject(new Error('Please input goal name!'))
              },
            }),
          ]}>
          <Input className={styles.fieldInput} placeholder={'Enter Goal name'} name={'goal_name'} />
        </Form.Item>
        <Form.Item name={'fixed_instruments'} label={'Fixed instruments'}>
          <InstrumentWeightSelect onOk={simulate} />
        </Form.Item>
        <Form.Item
          className={styles.formItem}
          name={'categories'}
          label={'Categories'}
          tooltip={
            <div>
              <p className={styles.infoText}>
                Select categories which will be used to automatically configure portfolio
              </p>
            </div>
          }>
          <CategoriesSelect mode={'multiple'} onChange={simulate} className={styles.fieldInput} />
        </Form.Item>
        <Form.Item
          className={styles.formItem}
          name={'target_amount'}
          label={'Target amount'}
          rules={[
            () => ({
              validator(_, value) {
                if (value) return Promise.resolve()
                else return Promise.reject(new Error('Please input target amount!'))
              },
            }),
          ]}
          tooltip={
            <div>
              <p className={styles.infoText}>The amount you want to accumulate</p>
            </div>
          }>
          <InputNumber type={'number'} prefix="$" className={styles.fieldInput} />
        </Form.Item>
        <Form.Item
          className={styles.formItem}
          name={'initial_amount'}
          label={'Initial amount'}
          dependencies={['target_amount']}
          hasFeedback
          rules={[
            ({ getFieldValue }) => ({
              validator(_, value) {
                if (value) {
                  if (getFieldValue('target_amount') > value) {
                    return Promise.resolve()
                  }
                  return Promise.reject(new Error('The target amount must be less than the initial amount!'))
                }
                return Promise.reject(new Error('Please input initial amount!'))
              },
            }),
          ]}>
          <InputNumber type={'number'} prefix="$" className={styles.fieldInput} />
        </Form.Item>

        <Form.Item
          className={styles.formItem}
          name={'payment_size'}
          label={'Payment size'}
          rules={[
            () => ({
              validator(_, value) {
                if (value) return Promise.resolve()
                else return Promise.reject(new Error('Please input payment amount!'))
              },
            }),
          ]}
          tooltip={
            <div>
              <p className={styles.infoText}>
                Payment size - the amount that will be debited according to the selected payment plan
              </p>
            </div>
          }>
          <InputNumber type={'number'} prefix="$" className={styles.fieldInput} />
        </Form.Item>

        <Form.Item
          className={styles.formItem}
          name={'payment_plan'}
          label={'Payment plan'}
          tooltip={
            <div>
              <p className={styles.infoText}>Write-off cash period</p>
            </div>
          }>
          <PaymentPlanSelect className={styles.fieldInput} />
        </Form.Item>

        <Form.Item
          className={styles.formItem}
          style={{ display: 'none' }}
          name={'current_amount'}
          label={'Current amount'}>
          <InputNumber prefix="$" />
        </Form.Item>

        <Form.Item name={'risk_level'} label={'Risk level'} className={styles.formItem}>
          <Slider className={classNames(styles.riskSlider, styles.fieldInput)} min={0} max={10} step={1} />
        </Form.Item>

        <Form.Item
          className={styles.formItem}
          name={'daily_drawdown'}
          label={'Daily drawdown'}
          getValueProps={(e) => ({ value: e * 100 })}
          getValueFromEvent={(e) => e / 100}
          tooltip={
            <div>
              <p className={styles.infoText}>
                The largest potential for loss in the value of an investment measured as a difference between the
                highest peak and the subsequent lowest trough in the market for a specific period of time.
              </p>
              <p className={styles.infoText}>
                For example: if balance is $ 10,000, and the value of Daily Drawdown is 10%, then the balance per day
                does not fall below $ 9000
              </p>
            </div>
          }>
          <Slider
            tipFormatter={(e) => e + '%'}
            onAfterChange={simulate}
            min={1}
            max={25}
            step={1}
            className={classNames(styles.dailyDrawdownSlider, styles.fieldInput)}
          />
        </Form.Item>

        <Form.Item
          className={styles.formItem}
          name={'sharpe'}
          label={'Sharpe'}
          tooltip={
            <div>
              <p className={styles.infoText}>
                This is the ratio of income with a risk amendment. It shows (in quantitative), what income (return to
                capital) is obtained at a given risk level.
              </p>
            </div>
          }>
          <Slider
            className={classNames(styles.sharpeSlider, styles.fieldInput)}
            onAfterChange={simulate}
            min={0.5}
            max={4.0}
            step={0.25}
          />
        </Form.Item>
      </div>

      <AccumulationChart
        loading={chartLoading}
        payments={data.payments}
        simulate={simulate}
        // data={[{ name: 'Goal balance', data: data.chart }]}
        charts={data.chart}
        className={styles.accumulationChart}
      />
      <FundsChart className={styles.fundsChart} />

      <Form.Item
        className={styles.formItem}
        name={'payments'}
        label={'Payments schedule'}
        style={{ display: 'none' }}
        tooltip={
          <div>
            <p className={styles.infoText}>
              Payment schedule - a table showing what date and how much will be withdrawn from your account
            </p>
          </div>
        }>
        <Payments />
      </Form.Item>

      <Button
        type={'primary'}
        onClick={() => onSubmit(form)}
        size={'large'}
        className={styles.submitBtn}
        disabled={chartLoading}
        loading={props.isLoading}>
        {props.submitBtnText ?? 'Create goal'}
      </Button>
    </Form>
  )
}

export default ConfiguratorForm
