import { Button, Empty, Form, Input, message, Row, Spin } from 'antd'
import React, { useEffect, useState } from 'react'
import * as api from 'api'
import { ReloadOutlined } from '@ant-design/icons'

export type Fund = {
  name: string
  id: number
}

const ProfileEdit = (props: { fundId: number }) => {
  const [form] = Form.useForm()
  const [l, sl] = useState(false)
  const [fund, setFund] = useState<undefined | null | Fund>()
  const onOk = async () => {
    const values = await form.validateFields().catch(() => null)
    if (!values) return
    sl(true)
    const resp = await api.updateFund(props.fundId, values)
    if (resp.data) {
      message.success('Fund updated')
    } else {
      message.error('Error occurred while updating fund')
    }
    sl(false)
  }
  useEffect(() => {
    getFund()
  }, [])
  const getFund = async () => {
    setFund(undefined)
    const resp = await api.getFund(props.fundId)
    if (resp.data) {
      setFund(resp.data)
    } else {
      message.error('Error occurred while loading fund profile')
      setFund(null)
      // setFund({ name: 'the fund' })
    }
  }
  if (fund === undefined)
    return (
      <div style={{ display: 'flex', alignItems: 'center', justifyContent: 'center', height: 400, width: '100%' }}>
        <Spin />
      </div>
    )
  if (fund === null)
    return (
      <div
        style={{
          display: 'flex',
          alignItems: 'center',
          justifyContent: 'center',
          height: 400,
          width: '100%',
          flexDirection: 'column',
        }}>
        <Empty description={'Error occurred while loading fund profile'} />
        <Button icon={<ReloadOutlined />} onClick={getFund} style={{ marginTop: 20 }} />
      </div>
    )
  return (
    <Form form={form} initialValues={fund}>
      {/*<Form.Item*/}
      {/*  name={'fund_image'}*/}
      {/*  label={'fund image'}*/}
      {/*  valuePropName={'fileList'}*/}
      {/*  getValueFromEvent={(e) => {*/}
      {/*    if (Array.isArray(e)) {*/}
      {/*      return e*/}
      {/*    }*/}
      {/*    return e && e.fileList*/}
      {/*  }}>*/}
      {/* */}
      {/*</Form.Item>*/}
      <Form.Item name={'name'} label={'Name'} required>
        <Input />
      </Form.Item>
      <Row justify={'end'}>
        <Button type={'primary'} onClick={onOk} loading={l}>
          Save
        </Button>
      </Row>
    </Form>
  )
}
export default ProfileEdit
