import React, { useContext, useEffect, useRef, useState } from 'react'
import { Select } from 'antd'
import { Category } from 'typings/dataStructures'
import * as api from 'api'
import { Fund } from 'pages/Backend/components/ProfileEdit'
type Props = {
  onChange?: (arg: number[]) => void
  value?: number[]
  onlyMy?: boolean
  mode?: undefined | 'multiple'
  onFundsLoad?: (arg: Fund[]) => void
}

const FundsSelect: React.FC<Props> = (props) => {
  const [funds, setFunds] = useState<Fund[]>()
  useEffect(() => {
    getFunds()
  }, [])
  const getFunds = async () => {
    const resp = await (props.onlyMy === true ? api.getFunds : api.getAllFunds)().catch(() => null)

    if (resp) {
      props.onFundsLoad?.(resp.data)
      setFunds(resp.data)
    }
  }
  return (
    <Select
      style={{ width: 200 }}
      placeholder={'Select fund'}
      mode={props.mode}
      value={props.value}
      onChange={props.onChange}
      loading={!funds}
      options={funds?.map((c) => ({ label: c.name, value: c.id }))}
    />
  )
}
export type SectorContextValueType = {
  secType: undefined | number
  sector: undefined | number
  industry: undefined | number
}
export const SectorContext = React.createContext<{
  ctx: SectorContextValueType
  setCtx: (arg: SectorContextValueType) => void
}>({
  ctx: { secType: undefined, sector: undefined, industry: undefined },
  setCtx: (arg: SectorContextValueType) => {},
})

export const SectorTypeSelect: React.FC<{ onChange?: (arg: number) => void; value?: number | undefined }> = (props) => {
  const [funds, setFunds] = useState<{ name: string; id: number }[]>()
  useEffect(() => {
    getFunds()
  }, [])
  const getFunds = async () => {
    const resp = await api.getSectorTypes().catch(() => null)

    if (resp) {
      setFunds(resp.data)
    }
  }
  const c = useContext(SectorContext)
  const onChange = (e: number) => {
    c.setCtx({ ...c.ctx, secType: e })
    props.onChange?.(e)
  }

  return (
    <Select
      style={{ width: 200 }}
      placeholder={'Select sector type'}
      value={props.value}
      onChange={onChange}
      loading={!funds}
      options={funds?.map((c) => ({ label: c.name, value: c.id }))}
    />
  )
}

export const SectorSelect: React.FC<{ onChange?: (arg: number | undefined) => void; value?: number | undefined }> = (
  props,
) => {
  const [sectors, setSectors] = useState<{ name: string; id: number }[]>()
  // useEffect(() => {
  //   getSectors()
  // }, [])
  const c = useContext(SectorContext)
  const secType = c.ctx.secType
  const getSectors = async (secType: number) => {
    const resp = await api.getSectors(secType).catch(() => null)

    if (resp) {
      setSectors(resp.data)
    }
  }
  const first = useRef(true)
  useEffect(() => {
    if (secType !== undefined) {
      getSectors(secType)
    } else {
      setSectors([])
    }
    // if (first.current) {
    //   first.current = false
    //   if (secType !== undefined) {
    //     getSectors(secType)
    //   }
    //   return
    // }
    // props.onChange?.(undefined)
  }, [secType])
  const onChange = (e: number) => {
    c.setCtx({ ...c.ctx, sector: e })
    props.onChange?.(e)
  }
  return (
    <Select
      style={{ width: 200 }}
      placeholder={'Select sector'}
      value={props.value}
      onChange={onChange}
      disabled={secType === undefined}
      loading={!sectors}
      options={sectors?.map((c) => ({ label: c.name, value: c.id }))}
    />
  )
}

export const IndustrySelect: React.FC<{ onChange?: (arg: number | undefined) => void; value?: number | undefined }> = (
  props,
) => {
  const [industries, setIndustries] = useState<{ name: string; id: number }[]>()
  useEffect(() => {
    // getFunds()
  }, [])
  const getIndustries = async (sector: number) => {
    const resp = await api.getIndustries(sector).catch(() => null)

    if (resp) {
      setIndustries(resp.data)
    }
  }
  const c = useContext(SectorContext)
  const secType = c.ctx.secType
  const sector = c.ctx.sector
  useEffect(() => {
    if (sector !== undefined && secType !== undefined) {
      getIndustries(sector)
    } else {
      setIndustries([])
    }
    // props.onChange?.(undefined)
  }, [secType, sector])
  const onChange = (e: number) => {
    c.setCtx({ ...c.ctx, sector: e })
    props.onChange?.(e)
  }
  return (
    <Select
      style={{ width: 200 }}
      placeholder={'Select industry'}
      value={props.value}
      onChange={onChange}
      loading={!industries}
      disabled={sector === undefined || secType === undefined}
      options={industries?.map((c) => ({ label: c.name, value: c.id }))}
    />
  )
}

export default FundsSelect
