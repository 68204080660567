import axios, { AxiosResponse } from 'axios'
import { Goal, GoalPreset, Payment } from './typings/dataStructures'
import { store } from './store'
import { profileActions } from './store/reducers/profileReducer'
import { message } from 'antd'
import { Fund } from './pages/Backend/components/ProfileEdit'

console.log('process.env', process.env)

export const __VERSION__ = process.env.REACT_APP_VERSION
export const __DEV__ = process.env.NODE_ENV === 'development'
export const __DEV__1 = 'test'
export const TUNEBOARD_URL = 'http://192.168.0.142:8000'
export const API_URL = 'https://wealth.splinex.com/api'
// export const API_URL = process.env.REACT_APP_API_URL
console.log('__DEV__', __DEV__)
console.log('API_URL', API_URL)
export const DJANGO_ADMIN = API_URL?.replace('/api', '/admin')

export const apiClient = axios.create({
  baseURL: API_URL,
  headers: {
    'Content-Type': 'application/json; charset=utf-8',
  },
})

apiClient.interceptors.request.use(
  (request) => {
    const token = store.getState().profile.token
    if (!request.headers) {
      request.headers = {}
    }
    if (token) {
      request.headers['Authorization'] = token
    }
    // const selectedWorkspace = store.getState().profile.selectedWorkspace;
    // if (request.headers['x-workspacepk'] === undefined) {
    //   request.headers['x-workspacepk'] = selectedWorkspace?.pk;
    // }
    return request
  },
  (error) => error,
)

let lastTokenError = 0
apiClient.interceptors.response.use(
  (response) => {
    return response
  },
  (error) => {
    if (error.response?.status === 403) {
      const t = new Date().getTime()
      if (t - 5000 > lastTokenError) {
        lastTokenError = t
        message.error('Your access token has been expired. Try login again')
      }
      store.dispatch(profileActions.logout())
    }
    // error.toString()
    return { error }
  },
)
type registerData = {
  email: string
  password: string
  first_name: string
  last_name: string
}
export const register = (data: registerData) => {
  return apiClient.post<registerData, AxiosResponse<{ token: string; id: number; username: string }>>('/register', data)
}

export const login = (username: string, password: string) => {
  return apiClient.post<
    { username: string; password: string; email: string },
    AxiosResponse<{ token: string; id: number; username: string; first_name?: string; last_name?: string }>
  >('/login', {
    username,
    password,
    email: username,
  })
}

export const logout = () => {
  return apiClient.get('/logout')
}

export const getGoalPreset = (pk?: number) => {
  return apiClient.get('/goal_preset', { params: { pk } })
}

export const createGoalPreset = (data: GoalPreset) => {
  return apiClient.post('/goal_preset', data)
}

export const updateGoalPreset = (pk: number, data: GoalPreset) => {
  return apiClient.patch('/goal_preset', data, { params: { pk } })
}

export const deleteGoalPreset = (pk: number) => {
  return apiClient.delete('/goal_preset', { params: { pk } })
}

export const getPaymentPlans = () => {
  return apiClient.get('/payment_plans')
}

export const getGoals = () => {
  return apiClient.get<Goal[]>('/goals')
}

export type CreateGoalReq = Omit<Goal, 'instruments' | 'payments' | 'id'> & {
  instruments: { instrument: number; weight: number; is_fixed: boolean }[]
  payments: { date: number; amount: number }[]

  bound_k: number
  annual_profit: number
  monthly_profit: number
  daily_profit: number
  weekly_profit: number
  estimate_date: number
}
export type CreateGoalResp = AxiosResponse<Goal>

export const createGoal = (data: CreateGoalReq) => {
  return apiClient.post<CreateGoalReq, CreateGoalResp>('/goal/0', data)
}

export const updateGoal = (id: number, goal: CreateGoalReq) => {
  return apiClient.patch<CreateGoalReq, CreateGoalResp>('/goal/' + id, goal)
}

export const deleteGoal = (id: number) => {
  return apiClient.delete('/goal/' + id)
}

export const getGoal = (id: number) => {
  return apiClient.get('/goal/' + id)
}

export type ChartReq = {
  target_amount: number
  initial_amount: number
  payment_plan: string
  payment_size: number
  risk_level: number
}
type ChartResp = {
  annual_profit: number
  chart: number[]
  daily_profit: number
  down_bound: number[]
  growth_amount: number
  monthly_profit: number
  paid_amount: number
  payments: Payment[]
  instruments: { instrument: string; weight: number; id: number; is_fixed: boolean }[]
  times: number[]
  up_bound: number[]
  weekly_profit: number
  bound_k: number
  // estimate_date: number
}

export const doChart = (data: ChartReq, signal?: AbortSignal) => {
  return apiClient.post<ChartReq, AxiosResponse<ChartResp>>('/do_chart', data, {
    signal: signal,
  })
}

export const getCategories = () => {
  return apiClient.get('/categories')
}

export const getProducts = (fundId: number) => {
  return apiClient.get(`/fund/${fundId}/instruments`)
}

export const getFund = (id: number) => {
  return apiClient.get('/fund/' + id)
}
export const getFunds = () => {
  return apiClient.get('/funds')
}
export const getAllFunds = () => {
  return apiClient.get('/getAllFunds')
}

export const updateFund = (pk: number, fund: Partial<Fund>) => {
  return apiClient.patch('/fund/' + pk, fund)
}

export const createFund = (fund: Partial<Fund>) => {
  return apiClient.post('/fund', fund)
}

export type InstrumentProps = {
  name: string
  category: number
  sector_type: number
  sector: number
  industry: number
  fund: number
}
export type Category = { id: number; name: string }
export type SectorType = { id: number; name: string }
export type Sector = { id: number; name: string }
export type Industry = { id: number; name: string }
export type InstrumentType = {
  // id: number
  // name: string
  // category: Category
  // sector_type: SectorType
  // sector: Sector
  // industry: Industry
  // chart: [number, number][]
  // annual_profit: number
  // // fund: number
  //

  id: number
  name: string
  source: string
  source_id: string
  daily_profit: number
  weekly_profit: number
  monthly_profit: number
  annual_profit: number
  daily_std_dev: number
  category: Category | null
  sector_type: SectorType | null
  sector: Sector | null
  industry: Industry | null
  chart: [number, number][]
}

type Instrument = {
  id: number
  name: string
  source: string
  source_id: string
  daily_profit: number
  weekly_profit: number
  monthly_profit: number
  annual_profit: number
  daily_std_dev: number
  category: Category | null
  sector_type: SectorType | null
  sector: Sector | null
  industry: Industry | null
  chart: [number, number][]
}

export const createInstrument = (data: InstrumentProps) => {
  return apiClient.post('/instrument', data)
}

export const getInstruments = () => {
  return apiClient.get<InstrumentType[]>('/instruments')
}

export const updateInstrument = (id: number, data: Partial<InstrumentProps>) => {
  return apiClient.patch('/instrument/' + id, data)
}
export const getInstrument = (id: number) => {
  return apiClient.get('/instrument/' + id)
}

/*----------------selects---*/
export const getSectors = (sector_type: number) => {
  return apiClient.get('/sectors', { params: { sector_type } })
}
export const getSectorTypes = () => {
  return apiClient.get('/sector_types')
}
export const getIndustries = (sector: number) => {
  return apiClient.get('/industries', { params: { sector } })
}
export const instrumentUploadCSV = (id: number, fd: FormData) => {
  return apiClient.post<FormData, AxiosResponse<InstrumentType>>(`/upload_csv/${id}`, fd)
}
export const deleteInstrument = (id: number) => {
  return apiClient.delete('/instrument/' + id)
}
