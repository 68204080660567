import React from 'react'
import Configurator from './Configurator'
import NewGoalWithTemplate from './NewGoalWithTemplate'

const ConfiguratorContainer = () => {
  const templateIdUrl = new URL(window.location.href).searchParams.get('templateId')
  const nameUrl = new URL(window.location.href).searchParams.get('name')

  let templateId: number | null = null
  if (templateIdUrl !== null) templateId = parseInt(templateIdUrl)

  if (templateId !== null && !isNaN(templateId)) {
    return <NewGoalWithTemplate templateId={templateId} nameUrl={nameUrl ?? ''} />
  } else return <Configurator />
}

export default ConfiguratorContainer
