import React, { useRef, useState } from 'react'
import styles from './ShareGoalModal.module.css'
import { Goal } from '../../typings/dataStructures'
import QRCode from 'react-qr-code'
import { useSelector } from 'store'

const ShareGoalModal = ({ goal }: { goal: Goal }) => {
  const [offerCopy, setOfferCopy] = useState(true)
  const name = useSelector((state) => state.profile.user?.first_name)
  const linkRef = useRef<HTMLInputElement>(null)
  const link =
    window.location.origin +
    '/configurator?templateId=' +
    goal.id +
    (name !== undefined && name !== '' ? `&name=${name}` : '')

  const onClick = () => {
    if (!linkRef.current) return
    linkRef.current.select()
    linkRef.current.setSelectionRange(0, 99999)
    document.execCommand('copy')
    setOfferCopy(false)
    setTimeout(() => {
      setOfferCopy(true)
    }, 2000)
  }

  return (
    <div className={styles.container}>
      <div className={styles.qrBlock}>
        <QRCode value={link} size={256} />
      </div>
      <div className={styles.linkBlock}>
        <input ref={linkRef} className={styles.linkInput} readOnly value={link} />
        <button className={styles.copyBtn} onClick={onClick}>
          {offerCopy ? 'copy' : 'copied!'}
        </button>
      </div>
    </div>
  )
}

export default ShareGoalModal
